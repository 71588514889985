import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Grid, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import RefreshIcon from 'assets/refresh.png'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import AddIcon from 'assets/add.png'
import axios from 'axios'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import SuiSelect from 'components/SuiSelect'
import SuiTypography from 'components/SuiTypography'
import { createBrowserHistory } from 'history'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import AddNotes from './AddNotes'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from '@mui/material/Button'
import { useAlert } from 'react-alert'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
import Moment from 'moment'

const history = createBrowserHistory({ forceRefresh: true })
var providersRedux = []
var typesRedux=[]
var facilitiesRedux = []
function PatientNotes(props) {
 const readOnly=props.readOnly
  const alert = useAlert()
  const [noteRows, setNoteRows] = useState([])
  const [refresh, setrefresh] = useState(false)
  const [visitDate, setVisitDate] = useState('')
  const [provider, setProvider] = useState('')
  const [type, setType] = useState('')
  const [TypeName, setTypeName] = useState('')
  const [alerterror, setalerterror] = useState(false)
  const [alerterror1, setalerterror1] = useState(false)
  const [alerterror2, setalerterror2] = useState(false)
  const [alerterror3, setalerterror3] = useState(false)
  const [facility, setFacility] = useState('')
  const [newNote, setnewNote] = useState(false)
  const [openNote, setopenNote] = useState(false)
  const [patientnoteid, setpatientnoteid] = useState(0)
  // const [visitDate, setVisitDate] = useState(0)
  const [providerName, setproviderName] = useState(0)
  const [facilityy, setfacilityy] = useState(0)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`

  const onDownload = (event) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'Patient Notes'
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientNote/GeneratNotesPdfFile?patientnoteid=${patientnoteid}`,
        {
          headers,
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        link.href = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/pdf',
          })
        )
        link.click()
      })
  }

  const headers = {
    Authorization: auth,
  }
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )
  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )
  const handletypeChange = (event) => {

    setType(event.value)
    setTypeName(event.label)
  }
  const handleProviderChange = (event) => {
    // console.log('event:', event)
    setProvider(event.value)
    setproviderName(event.label)
  }
  const handleFacilityChange = (event) => {
    setFacility(event.value)
    setfacilityy(event.label)
  }
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (cell) => {
    setSelectedCell(cell);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setSelectedCell(null);
    setOpenAlert(false);
  };
  const handleDeleteById = () => {
    if (!selectedCell) {
      // Handle error or show a message as needed
      console.error('No cell selected for deletion.');
      return;
    }

    const postData = {
      id: selectedCell.row.id,
      inactive: true,
      visitDate: selectedCell.row.visitDate,
      patientNoteId: selectedCell.row.patientNoteId
      // userId: selectedCell.row.userId,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientNote/AddPatientNote`, postData, {
        headers,
      })
      .then((response) => { alert.success('Record Deleted successfully.')
        // Handle success, e.g., show a success message
        console.log('Record Deleted successfully.');
        setrefresh(!refresh);
        setLoading(false);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error:', error);
        setLoading(false);
      });

    handleCloseAlert();
  };
  const componentMounted = useRef(true) // (3) component is mounted
  const styles = {
    largeIcon: {
      width: 60,
      height: 60,
    },
  }
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
       borderRadius: 1.8,
    },
  }
  const noteColumns = [
    {
      field: 'visitDate',
      headerName: 'Visit Date',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <SuiButton
            size="small"
            variant="text"
            color="success"
            onClick={(event) => {
              setpatientnoteid(cellValues.row.patientNoteId)
              setVisitDate(cellValues.row.visitDate)
              setproviderName(cellValues.row.provider)
              setType(cellValues.row.type)
              setfacilityy(cellValues.row.facility)
              setopenNote(true)
              /*   history.push({
                pathname: `/AddNotes`,
                state: {
                  patientnoteid: cellValues.row.patientnoteid,
                  patientId: props.patientId
                },
              }) */
            }}
          >
            {cellValues.row.visitDate}
          </SuiButton>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Note Type',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'medicalNotes',
      headerName: 'Notes',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      sortable: true,

      renderCell: (cellValues) => {
        var data = (
          <div
            dangerouslySetInnerHTML={{ __html: cellValues.row.medicalNotes }}
          />
        )
        return data
      },
    },
    {
      field: 'provider',
      headerName: 'Provider',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'facility',
      headerName: 'Facility',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'signed',
      headerName: 'Signed',
      cellClassName: 'gridCell',
      sortable: true,
      type: 'boolean',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'signedBy',
      headerName: 'Signed By',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'signedDate',
      headerName: 'Signed Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
   
     {
      // field: '',
      headerName: 'Delete',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      width: 40,
      renderCell: (cell) => (<>
        <Button onClick={() => handleClickOpen(cell)}>
        <DeleteIcon
          color="red"
          style={{
            marginRight: '2rem',
            float: 'left',
            marginTop: '5px',
          }}
        />
      </Button>
      <Dialog
                  open={openAlert}
                  onClose={handleCloseAlert}
                  aria-labelledby="responsive-dialog-title"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {background:'#cef5f0',
                    boxShadow:'none',
                    borderRadius:'8px'
                    },
                 }}                >





<div style={{borderRadius:'8px'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={handleDeleteById} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={handleCloseAlert} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


                
                </Dialog>
                </>
      ),
    },
  
  ]
  const [selectedValue, setSelectedValue] = useState(null); // State to store the selected value.

  const options = [
    { value: "office visit", label: "office visit" },
    { value: "historical note", label: "historical note" },
  
  ];

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };
  const handleSubmit = () => {}
  useEffect(() => {
    // POST request using axios inside useEffect React hook

    const fetchData = async () =>
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/PatientNote/getPatientNote?PatientId=${
            props.patientId !== undefined ? props.patientId : 0
          }`,
          {
            headers,
          }
        )
        .then((response) => {
          const data = []
          // console.log('data: ', response.data)
          setNoteRows(response.data)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()

    return () => {
      componentMounted.current = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  },[refresh])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
       providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [providersRedx])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    typesRedux = []
    console.log('reduxxx', typesRedux)
    providersRedx.forEach((field) => {
      typesRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [typesRedux])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [facilitiesRedx])
  const newNotesHandler = (event, id) => {
     const postData = {
      patientId: parseInt(props.patientId),
      providerId: parseInt(provider),
      type: type,
      facilityId: parseInt(facility),
      visitDate: visitDate,
      signedDate: null,
    }
    if (visitDate !== undefined && visitDate.length !== 0) {
      setalerterror(false);
    } else {
      setalerterror(true);
      return '';
      // alert.success('Data is Requrid')
    }
  
    if (provider !== undefined && provider.length !== 0) {
      setalerterror2(false);
    } else {
      setalerterror2(true);
      return '';
      // alert.success('Data is Requrid')
    }
    if (type !== undefined && type.length !== 0) {
      setalerterror3(false);
    } else {
      setalerterror3(true);
      return '';
      // alert.success('Data is Requrid')
    }
    if (facility !== undefined && facility.length !== 0) {
      setalerterror1(false);
    } else {
      setalerterror1(true);
      return '';
      // alert.success('Data is Requrid')
    }
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientNote/AddPatientNote`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('PatientNote: ', response.data)

         setpatientnoteid(response.data.patientNoteId)
        setnewNote(false)
         setopenNote(true)

      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  return (
    <>
      {openNote ? (
        <Grid
          container
          xs={12}
          spacing={1}
          style={{
            marginTop: '10px',
            borderRadius: '20px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
          }}
        >
          <Grid
            container
            // xs={auto}
            sm={12}
            md={12}
            lg={12}
            style={{ width: '100%', marginTop: '1px', borderRadius: '20px' }}
            bgcolor={'rgb(161,230,246)'}
          >
            <ArrowBackIosIcon
              color="black"
              style={{ marginLeft: '5px', float: 'left', marginTop: '5px' }}
              onClick={(event) => {
                setopenNote(false)
                setnewNote(false)
                  setrefresh(!refresh)
                  setpatientnoteid(0)
                  setVisitDate('')
                setproviderName('')
                setType('')
                setfacilityy('')
                // fields.forEach((field) => setValue(field, ''))
              }}
              variant="gradient"
            />
            <Grid item spacing={1} xs={6} sm={1.1} md={1.1} lg={1.1}>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#2188FE"
              >ID:</Typography>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#333333"
              >{patientnoteid}</Typography>
            </Grid>
            <Grid item spacing={1} xs={6} sm={2.4} md={2.4} lg={2.4}>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#2188FE"
              >
                Visit Date:
              </Typography>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#333333"
              >
                {Moment(visitDate).format('MM/DD/YYYY')}
              </Typography>
            </Grid>
           
            <Grid item spacing={1} xs={6} sm={2.7} md={2.7} lg={2.7}>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#2188FE"
              >
                Facility:
              </Typography>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#333333"
              >
                {facilityy}
              </Typography>
            </Grid>
            <Grid item spacing={1} xs={6} sm={2.4} md={2.4} lg={2.4}>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#2188FE"
              >
                Provider:
              </Typography>
              
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#333333"
              >
                {providerName}
              </Typography>

              
            </Grid>
            <Grid item spacing={0} xs={'auto'} sm={2.5} md={2.5} lg={2.5}>
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#2188FE"
              >
                Type:
              </Typography>
              
              <Typography
                style={{
                  height: '20px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  // marginRight: '10px',
                }}
                component="label"
                variant="caption"
                fontWeight="bold"
                color="#333333"
              >
                {type}
              </Typography>

              
            </Grid>
            <Grid item spacing={1} xs={6} sm={0.5} md={0.5} lg={0.5}>
              <SuiButton
                onClick={onDownload}
                variant="gradient"
                color="info"
                // disabled={readOnly==true?true:false}
                size="small"
                style={{
                  width: '20px',
                  float: 'right',
                  alignItems:'right',
                  textAlign:'right',


                  // display: 'inline-block',
                  // marginLeft: '68px',
                }}
                // startIcon={
                //   <img
                //     src={AddIcon}
                //     style={{ marginTop: '-3px', marginRight: '-6px' }}
                //   />
                // }
              >
                PDF
              </SuiButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AddNotes
              patientnoteid={patientnoteid}
              patientId={props.patientId}
              readOnly={readOnly}
            ></AddNotes>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
            <Button
              sx={{ mb: 2 }}
              onClick={(event) => {
                setnewNote(true)
                setpatientnoteid(0)
                setVisitDate('')
              setproviderName('')
              setType('')
              setfacilityy('')
              }}
              variant="gradient"
              // disabled={readOnly==true?true:false}
              color="info"
              size="small"

              style={{
                width: '70px',
                float: 'right',
                height: '33px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
              // color="warning"
              // size="small"

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <AddOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '4px',
                  paddingBottom: '2px',
                }}
              ></AddOutlinedIcon>
             New
            </Button>

            <Button
              sx={{ mb: 2, marginRight: '20px' }}
              onClick={(event) => {
                setrefresh(!refresh)

              }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '120px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <CachedOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  marginRight:"1px",
                  paddingRight: '0px',
                  paddingBottom: '2px',
                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button>
          </Grid>
          <SuiBox mb={1}>
            {newNote == true ? (
              <div>
                <Grid
                  container
                  spacing={1}
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '30px',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    borderRadius: '20px',
                    border: '1px solid rgba(5, 152, 236, 0.637)',
                  }}
                >
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Visit Date
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Provider
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <SuiTypography

                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Facility
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <SuiInput
                    onChange={(e) => setVisitDate(e.target.value)}
                    value={visitDate}
                    type="date"
                    margin="dense"
                      // onChange={(e) => {setVisitDate(Moment(e.target.value).format('MM-DD-YYYY'))
                      //    setalerterror(false)
                      // }}

                      // // value={visitDate}
                      // type="date"
                      
                    />
                    <p style={{ color: 'red' }}>
                      {alerterror ? 'Please Select Visit Date' : null}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>

                    <SuiSelect
                        isClearable
                      value={providersRedux.filter(function (option) {
                        return option.value === provider
                      })}
                      onChange={handleProviderChange}
                      options={providersRedux}
                    ></SuiSelect> <p style={{ color: 'red' }}>
                    {alerterror2 ? 'Please Select Provider' : null}
                  </p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <SuiSelect
                        isClearable
                      value={facilitiesRedux.filter(function (option) {
                        return option.value === facility
                      })}
                      onChange={handleFacilityChange}
                      options={facilitiesRedux}
                    ></SuiSelect> <p style={{ color: 'red' }}>
                    {alerterror1 ? 'Please Select Facility' : null}
                  </p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                    Notes Type
                    </SuiTypography>
                    {/* <div>
      <SuiSelect
        placeholder="Select Type"
        options={options}
        onChange={handleSelectChange}
        value={selectedValue}
      
      />
      
    </div> */}
                    <SuiSelect
    isClearable
  value={options.filter(function (option) {
    return option.value === type
    setType(type)
  })}
  onChange={handletypeChange}
  options={options}
></SuiSelect><p style={{ color: 'red' }}>
                    {alerterror3 ? 'Please Select Note' : null}
                  </p>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>


</Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      sx={{ mb: 5, float: 'right', marginLeft: '10px' }}
                      onClick={(event) => {
                        setnewNote(false)
                        setpatientnoteid(0)
                setVisitDate('')
              setproviderName('')
              setTypeName('')
              setfacilityy('')
                      }}
                      variant="gradient"
                      style={{
                        marginBottom: '10px',
                        float: 'right',
                        marginLeft: '20px',
                        width: '90px',
                        height: '35px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                      // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CancelOutlinedIcon
                        fontSize="medium"
                        style={{ color: 'red', paddingRight: '5px' }}
                      ></CancelOutlinedIcon>
                      Cancel
                    </Button>
                    <Button
                      sx={{ mb: 5, float: 'right' }}
                      onClick={newNotesHandler}
                      variant="gradient"
                      color="info"
                      disable={true}
                      size="small"
                      style={{
                        width: '70px',
                        float: 'right',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                      // color="warning"
                      // size="small"

                      // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <SaveOutlinedIcon
                        fontSize="medium"
                        style={{
                          color: '#1a82ad',
                          paddingRight: '4px',
                          paddingBottom: '2px',
                        }}
                      ></SaveOutlinedIcon>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <SuiBox mb={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ height: 353.5, width: '100%' }}
                >
                  <DataGrid
                    // getRowId={(row) => (row != null ? row.patientNoteId : 0)}
                    rows={noteRows}
                    columns={noteColumns}
                    headerHeight={37}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={gridRowStyle}

                    initialState={{
                      sorting: {
                         sortModel: [{ field: "visitDate", sort: "asc" }],
                      },
                   }}
                  />
                </Grid>
              </SuiBox>
            )}
          </SuiBox>
        </Grid>
      )}
    </>
  )
}

export default PatientNotes
