/* eslint-disable object-shorthand */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import Select from "@mui/material/Select";
import AddIcon from "assets/add.png";

import SaveIcon from "assets/save.png";
import CancelIcon from "assets/cancel.png";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useAlert } from "react-alert";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import input from "@mui/material/Input";
import { Grid, Snackbar, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useReducer, useState, Fragment } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PermIdentity from "@mui/icons-material/PermIdentity";
import WorkOutline from "@mui/icons-material/WorkOutline";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { confirmAlert } from "react-confirm-alert";
import { styled } from "@mui/material/styles";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Button from "@mui/material/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Avatar from "@mui/material/Avatar";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CircularProgress from "@mui/material/CircularProgress";

const history = createBrowserHistory({ forceRefresh: true });
const Input = styled("input")({
  display: "none",
});
const gridRowStyle = {
  boxShadow: 2,
  border: 2,
  borderRadius: 5,
  background: "white",
  fontSize: "13.6px !important",
  color: "#8392ab !important",
  borderColor: "rgba(5, 152, 236, 0.637) !important",
  "& .MuiDataGrid-cell:hover": {
    color: "rgba(5, 152, 236, 0.637) !important",
    fontSize: "14.6px !important",
  },
  '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
       borderRadius: 1.8,
    },
};

const fields = [
  "city",
  "contactNo",
  "createdBy",
  "createdDate",
  "dateJoined",
  "defaultFacilityId",
  "defaultPracticeId",
  "dob",
  "email",
  "fullName",
  "gender",
  "id",
  "provider_id",
  "deleted",
  "isStaff",
  "isSuperUser",
  "isVerified",
  "lastLogin",
  "password",
  "profilePhoto",
  "state",
  "street",
  "token",
  "updatedBy",
  "updatedDate",
  "userType",
  "zipCode",
  "provider",
  "address",
  "PracticeId",
  "photoUrl",
];
const defValues = {
  city: "",
  contactNo: "",
  createdBy: "",
  createdDate: "",
  dateJoined: "",
  defaultFacilityId: "",
  defaultPracticeId: "",
  dob: "",
  email: "",
  fullName: "",
  gender: "",
  id: 0,
  provider_id: "",
  deleted: "",
  isStaff: "",
  isSuperUser: "",
  isVerified: "",
  lastLogin: "",
  password: "",
  profilePhoto: "",
  state: "",
  street: "",
  token: "",
  updatedBy: "",
  updatedDate: "",
  userType: "",
  zipCode: "",
  provider: "",
  address: "",
  PracticeId: "",
  photoUrl: "",
};
const practicesList = [];
const practiceslistid = [];
const facilitiesList = [];
var providersRedux = [];
var facilitiesRedux = [];

function handleCancel() {
  history.push("/users");
}
function UserRegistration() {
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      field: "id",
      headerClassName: 'super-app-theme--header',
      headerName: "Id",
      width: 100,
      sortable: true,
    },
    // {
    //   field: "userEmail",
    //   headerName: "Email Address",
    //   width: 200,
    //   sortable: true,
    // },

    {
      field: "practiceName",
      headerClassName: 'super-app-theme--header',
      headerName: "Practice Name",
      width: 300,
      sortable: true,
    },
    {
      field: "providerName",
      headerClassName: 'super-app-theme--header',
      headerName: "Default Provider",
      sortable: true,
      width: 300,
    },
    {
      field: "createdDate",
      headerClassName: 'super-app-theme--header',
      headerName: "Added Date",
      sortable: true,
      width: 300,
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: 'super-app-theme--header',
      sortable: true,
      width: 150,
      renderCell: (cell) => (
        <DeleteIcon
          style={{
            marginRight: "5px",
            float: "right",
            marginTop: "5px",
            color: "red",
          }}
          // color="black"
          onClick={(cellValues) => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: "Do you want to delete the record.",
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: "overlay-custom-class-name",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    const postData = {
                      createdBy: cell.row.createdBy,
                      createdDate: cell.row.createdDate,
                      facilityId: cell.row.facilityId,
                      facilityName: cell.row.facilityName,
                      id: cell.row.id,
                      deleted: true,
                      practiceId: cell.row.practiceId,
                      practiceName: cell.row.practiceName,
                      updatedBy: cell.row.updatedBy,
                      updatedDate: cell.row.updatedDate,
                      userEmail: cell.row.userEmail,
                    };

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/UserPractices/addUserPractices`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRefresh(!refresh);
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch((error) => {
                        console.error("There was an error!", error);
                      });
                  },
                },
                {
                  label: "No",
                },
              ],
            });
          }}
        ></DeleteIcon>
      ),
    },
  ];
  const columns1 = [
    {
      field: "Name",
      headerName: "Name",
      cellClassName: "gridCell",
      sortable: false,
      width: 130,
      renderCell: (cellValues) => (
        <SuiButton
          ize="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            openUserRegistration(event, cellValues.row.id);
          }}
        >{`${cellValues.row.Name}`}</SuiButton>
      ),
    },
    {
      field: "username",
      headerName: "Father Name",
      cellClassName: "gridCell",
      sortable: false,
      width: 130,
    },
    {
      field: "phoneno",
      headerName: "Phone No",
      cellClassName: "gridCell",
      sortable: false,
      width: 130,
    },
    {
      field: "address",
      headerName: "Address",
      cellClassName: "gridCell",
      sortable: false,
      width: 130,
    },
  ];
  const [userEmail, setUserEmail] = useState("");
  const [zipDownloaded, setzipDownloaded] = useState(false);
  // const [refresh, setrefresh] = useState(false)
  const [fetchedData, setfetchedData] = useState(false);
  const [userPractice, setUserPractice] = useState(0);
  const [userProvider, setUserProvider] = useState(0);
  const [userFacility, setUserFacility] = useState(0);
  const [rows, setRows] = useState(null);
  const [rows1, setRows1] = useState(null);
  const location = useLocation();
  const alert = useAlert();
  const zipCodeListStyle = {
    cursor: "pointer",
    "&:hover": {
      background: "#3b3b3b !important",
      color: "rgba(5, 152, 236, 0.637) !important",
    },
  };
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  );
  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  );
  const [submitting, setSubmitting] = useState(false);
  const [practiceID, setPracticeID] = useState("");
  const [providers, setProviders] = useState([]);
  const [defaultPracticeError, setDefaultPracticeError] = useState(false);
  const [defaultPracticeErrorId, setDefaultPracticeErrorId] = useState("");

  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(3, "Password must be at least 3 characters")
      .max(15, "Password must not exceed 15 characters"),
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const onSubmit = (data) => {
    setSubmitting(true);
    const postData = data;
    var passwordInputValue = postData.password;
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{4,}/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);
    let errMsg = "";
    if (passwordLength === 0) {
      errMsg = "Password is empty";
    } else if (!uppercasePassword) {
      errMsg = "At least one Uppercase";
    } else if (!lowercasePassword) {
      errMsg = "At least one Lowercase";
    } else if (!digitsPassword) {
      errMsg = "At least one digit";
    }
    // else if(!specialCharPassword){
    //         errMsg="At least one Special Characters";
    // }
    else if (!minLengthPassword) {
      errMsg = "At least minumum 4 characters";
    } else {
      errMsg = "";
    }
    if (errMsg.length > 0) {
      setError(
        "password",
        { type: "custom", message: errMsg },
        { shouldFocus: true }
      );
      // console.error('Passwordd: ',errMsg)
      setSubmitting(false);
      return;
    }
    if (postData.password != postData.confirmPassword) {
      setError(
        "confirmPassword",
        { type: "custom", message: "Passwords does not match" },
        { shouldFocus: true }
      );
      // console.error('Passwordd: ',errMsg)
      setSubmitting(false);
      return;
    }
    if (location.state.userId > 0 && defaultPracticeErrorId <= 0) {
      setDefaultPracticeError(true);
      setSubmitting(false);
      return;
    } else {
      setDefaultPracticeError(false);
      // setPasswordErr(errMsg);

      // postData.userType = data.userType ? data.userType.value : ''
      // postData.gender = data.gender ? data.gender.value : ''
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
          postData,
          { headers }
        )
        .then((response) => {
          setSubmitting(true);
          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              history.push("/users");
            },
          });
        })
        .catch((error) => {
          setSubmitting(false);
          alert.success(`Error ${error.message}`, {
            type: "error",
          });
        });
    }
  };
  const [practiceIdError, setPracticeIDError] = useState(false);
  const [providerIdError, setProviderIdError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const handleUserPractice = (event) => {
    // console.log('userPractice: ', userPractice)
    event.preventDefault();
    if (
      userPractice == null ||
      userPractice == undefined ||
      userPractice <= 0
    ) {
      setPracticeIDError(true);
      return;
    }
    if (
      userProvider == null ||
      userProvider == undefined ||
      userProvider <= 0
    ) {
      setProviderIdError(true);
      return;
    }
    //
    const duplicate = rows.filter((newVal) => {
      return newVal.practiceId == userPractice;
      // comparing category for displaying data
    });
    if (duplicate !== null && duplicate != undefined && duplicate.length > 0) {
      setDuplicateError(true);
      return;
    }
    const postData = {
      userEmail: userEmail,
      facilityId: userFacility,
      practiceId: userPractice,
      providerId: getValues("provider_id"),
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/UserPractices/addUserPractices`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        setSubmitting(true);
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            window.location.reload(false);
          },
        });
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true;
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Users/getUserById?id=${
            location.state.userId !== undefined ? location.state.userId : 0
          }`,
          { headers }
        )
        .then((response) => {
          if (isComponentMounted) {
            // setUsersData(response.data);
            fields.forEach((field) => {
              setValue(field, response.data[field]);
              if (field === "email") {
                setUserEmail(response.data[field]);
              }
              if (field === "password") {
                setValue("confirmPassword", response.data[field]);
              }
            });
            setPracticeID(response.data.defaultPracticeId);
            setDefaultPracticeErrorId(response.data.defaultPracticeId);
          }
          if (response.data.photoUrl) {
            // console.log('URL: ',`${process.env.REACT_APP_DOCUMENT_URL}`)
            var Url = `${process.env.REACT_APP_DOCUMENT_URL}/${response.data.photoUrl}`;
            // console.log('URL: ',Url)
            setImages(Url);
          }
          setfetchedData(true);
          setzipDownloaded(true);
        })
        .catch((error) => {});
    fetchData();
    return () => {
      isComponentMounted = false;
    };

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true;
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/UserPractices/getUserPractices?userEmail=${userEmail}`,
          { headers }
        )
        .then((response) => {
          if (isComponentMounted) {
            response.data.forEach((field) => {
              practiceslistid.push({
                label: field.practiceName,
                value: field.practiceId,
              });
            });
          }
        })
        .catch((error) => {});
    fetchData();
    return () => {
      isComponentMounted = false;
    };

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [userEmail, refresh]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true;
    const fetchData = async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/Practices/getPractices`, {
          headers,
        })
        .then((response) => {
          if (isComponentMounted) {
            response.data.forEach((field) => {
              practicesList.push({
                label: field.practiceName,
                value: field.id,
              });
            });
          }
        })
        .catch((error) => {});
    fetchData();
    return () => {
      isComponentMounted = false;
    };

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  const openUserRegistration = (event, id) => {
    history.push({
      pathname: `/AddNotes`,
      state: { userId: id },
    });
  };
  const openUserRegistration1 = (event, id) => {
    history.push({
      pathname: `/EmergencyContact`,
      state: { userId: id },
    });
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true;
    const fetchData = async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/Facilities/getFacilities`, {
          headers,
        })
        .then((response) => {
          if (isComponentMounted) {
            response.data.forEach((field) => {
              facilitiesList.push({ label: field.name, value: field.id });
            });
          }
        })
        .catch((error) => {});
    fetchData();
    return () => {
      isComponentMounted = false;
    };

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = [];
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      });
    });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ facilitiesRedx }]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/UserPractices/getUserPractices?userEmail=${userEmail}`,
        { headers }
      )
      .then((response) => setRows(response.data))
      .catch((error) => {});

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [userEmail, refresh]);
  useEffect(() => {
    setProviders([]);
    if(!practiceID){
      return
    }
    if (userPractice != null || userPractice != undefined || userPractice > 0) {
      // POST request using axios inside useEffect React hook
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
          { headers }
        )
        .then((response) => {
          var prov = [];
          response.data.forEach((field) => {
            prov.push({
              label: field.name,
              value: field.id,
            });
          });
          setProviders(prov);
        })
        .catch((error) => {});
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [practiceID]);
  const [tabValue, settabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    settabValue(newValue);
    setValue("defaultPracticeId", "");
    setValue("provider_id", "");
    // setPracticeID("");
    setProviderIdError(false);
    setPracticeIDError(false);
    setDefaultPracticeError(false);
  };
  const handleChange1 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  ///Auto Compelet
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [zipCodeList, setZipCodeList] = React.useState([]);
  const [isZipCode, setIsZipCode] = React.useState(false);

  const loading = open && options.length === 0;

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setzipDownloaded(false);
        setZipCodeList(response.data);
        setIsZipCode(true);
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  ////ImageUpload
  const [images, setImages] = React.useState(null);
  ///Delete Image
  const deleteimg = () => {
    setImages(null);
    setValue("photoUrl", "");
  };
  ///Upload Image
  const onImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    convertBase64(file);
    // console.log("64 image",base64)
    console.log(
      "Kdr Sy aya ha Console",
      URL.createObjectURL(e.target.files[0])
    );
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    }).then((users) => {
      setValue("base64", users);
    });
  };
  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "30%" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab value={0} icon={<PermIdentity />} label="USER INFO" />
          <Tab value={1} icon={<WorkOutline />} label="PRACTICES" />
        </TabList>
      </Box>
      <TabPanel value={0}>
        {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ) : null}
        <Grid
          container
          columnSpacing={1}
          alignContent="center"
          justifyContent="center"
          style={{
            paddingTop: "20px",
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <label htmlFor="icon-button-file">
                    <Input
                      id="icon-button-file"
                      type="file"
                      onChange={onImageChange}
                    />
                    <Avatar
                      sx={{ bgcolor: "#FCBE13" }}
                      src={images}
                      style={{
                        height: 120,
                        width: 120,
                        borderRadius: "100px",
                      }}
                    >
                      <AddAPhotoIcon
                        fontSize="large"
                        style={{ color: "black", marginBottom: "5px" }}
                      ></AddAPhotoIcon>
                    </Avatar>
                  </label>
                </Grid>
                {images && images.length > 0 ? (
                  <Button
                    onClick={deleteimg}
                    variant="gradient"
                    // disabled={submitting}
                    style={{
                      marginLeft: "20px",
                      height: "35px",
                      width: "70px",
                      color: "black",
                      fontSize: "14px",
                    }}
                    // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                  >
                    <DeleteOutlinedIcon
                      fontSize="medium"
                      style={{ color: "red", marginBottom: "5px" }}
                    ></DeleteOutlinedIcon>
                    Remove
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontSize="15px"
                  >
                    Full Name
                  </SuiTypography>
                </Grid>
                <Grid item xs={6}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontSize="15px"
                  >
                    Email
                  </SuiTypography>
                </Grid>

                <Grid item xs={6} style={{ width: "300px" }}>
                  <SuiInput
                    placeholder="Please enter Full Name"
                    name="fullName"
                    label="fullName"
                    fullWidth
                    margin="dense"
                    {...register("fullName")}
                    error={errors.fullName}
                  />
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.fullName?.message}
                  </p>
                </Grid>
                <Grid item xs={6} style={{ width: "300px" }}>
                  <SuiInput
                    placeholder="Email"
                    name="email"
                    label="Email"
                    // readOnly={location.state.userId ? true : false}
                    fullWidth
                    margin="dense"
                    {...register("email")}
                    error={errors.email}
                  />
                  <p style={{ color: "red" }}>{errors.email?.message}</p>
                </Grid>

                <Grid item xs={6}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontSize="15px"
                  >
                    Password
                  </SuiTypography>
                </Grid>
                <Grid item xs={6} style={{ width: "300px" }}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontSize="15px"
                  >
                    Confirm Password
                  </SuiTypography>
                </Grid>
                <Grid item xs={6} style={{ width: "300px" }}>
                  <SuiInput
                    type="password"
                    placeholder="Password"
                    required
                    id="password"
                    name="password"
                    label="Password"
                    fullWidth
                    margin="dense"
                    {...register("password")}
                    error={errors.password}
                  />
                  <p style={{ color: "red" }}>{errors.password?.message}</p>
                </Grid>
                <Grid item xs={6} style={{ width: "300px" }}>
                  <SuiInput
                    type="password"
                    placeholder="Confirm Password"
                    required
                    id="confirmpassword"
                    name="confirmpassword"
                    label="Confirm Password"
                    fullWidth
                    margin="dense"
                    {...register("confirmPassword")}
                    error={errors.confirmpassword}
                  />
                  <p style={{ color: "red" }}>
                    {errors.confirmPassword?.message}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              UserType
            </SuiTypography>
          </Grid>

          <Grid item xs={4} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Default Practice
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: "300px" }}>
            {/* <SuiTypography
                    component="label"
                    variant="caption"
                   fontSize="15px"
                  >
                    Provider
                  </SuiTypography> */}
          </Grid>
          <Grid item xs={4} style={{ width: "300px" }}>
            <Controller
              name="userType"
              {...register("userType")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  {...field}
                  style={{ size: "small" }}
                  value={{ label: getValues("userType") }}
                  placeholder=""
                  onChange={(value) => setValue("userType", value.value)}
                  options={[
                    { value: "Admin", label: "Super Administrator" },
                    { value: "Administrator", label: "Administrator" },
                    { value: "Provider", label: "Provider" },
                    { value: "ClinicalStaff", label: "Clinical Staff" },
                    { value: "NonClinicalStaff", label: "Non Clinical Staff" },
                    { value: "Billing", label: "Billing" },
                    {
                      value: "InformationTechnology",
                      label: "Information Technology",
                    },
                  ]}
                />
              )}
            />
          </Grid>

          <Grid item xs={4} style={{ width: "300px" }}>
            <Controller
              name="defaultPracticeId"
              {...register("defaultPracticeId")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  {...field}
                  value={practiceslistid.filter(function (option) {
                    return option.value === getValues("defaultPracticeId");
                  })}
                  // value={{ label: getValues('defaultPracticeId') }}
                  onChange={(value) => {
                    setValue("defaultPracticeId", value.value);
                    setPracticeID(value.value);
                    setDefaultPracticeErrorId(value.value);
                    setDefaultPracticeError(false);
                  }}
                  options={practiceslistid}
                />
              )}
            />
            {defaultPracticeError ? (
              <Typography
                color="error"
                style={{
                  fontSize: "14px",
                  marginTop: "15px",
                  marginLeft: "5px",
                }}
              >
                Please Select Default Practice!
              </Typography>
            ) : null}
          </Grid>

          <Grid item xs={4} style={{ width: "300px" }}>
            {/* <Controller
                    name="provider_id"
                    {...register('provider_id')}
                    control={control}
                    render={({ field }) => (
                      <SuiSelect
                        isClearable
                        {...field}
                        default
                        value={providers.filter(function (option) {
                          return option.value === getValues('provider_id')
                        })}
                        onChange={(value) => {
                          setValue('provider_id', value.value)
                        }}
                        options={providers}
                      /> 
                    )}
                  />*/}
          </Grid>

          <Grid item xs={8} style={{ width: "300px" }}>
            {/* <SuiInput
              type="input"
              placeholder="Enter Address"
              name="address"
              {...register('address')}
            /> */}
          </Grid>
          {/* <Grid item xs={4} style={{ width: '300px' }}>
            <SuiTypography
              component="label"
              variant="caption"
             fontSize="15px"
            >
              ZIP Code
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiTypography
              component="label"
              variant="caption"
             fontSize="15px"
            >
              City
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiTypography
              component="label"
              variant="caption"
             fontSize="15px"
            >
              State
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              type="input"
              placeholder="ZipCode"
              {...register('zipCode')}
              onKeyUp={(ev) => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== '' || ev.target.value !== null) {
                  onChangeHandle(ev.target.value)        
                setSearchText(ev.target.value)

                }
                else
                {
                  setSearchText('')

                }
              }}
            />
            {isZipCode ? (
              <div
                className="col-xs-3"
                style={{
                  padding: '14px 16px',
                  fontSize: '0.875rem',
                  color: '#67748e',
                  borderRadius: '5px',
                  background: 'white',
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                  position: 'absolute',
                  zIndex: '99',
                  width: '25%',
                  border: '1px solid #6cb5f9',
                }}
              >
                {zipCodeList.map((s, index) => {
                 
                  var pat = `${zipCodeList[index].zipCode}`
                  var i = pat.indexOf(searchText)
                  // console.log('i:',i)
                  var p1 = pat.substring(0, i)
                  var p = pat.substring(i, searchText.length)
                  // console.log('p:',p)
                  var p2 = pat.substring(
                    searchText.length,
                    pat.length
                  )
                  return(
                  <p
                    // attribZip={s.zipCode}
                    // attribState={s.state}
                    // attribCity={s.city}
                    // attribIndex={index}

                    style={zipCodeListStyle}
                    onClick={(ev) => {
                      // console.log('evvv', ev)
                      // var sel=ev.target.attributes['attribIndex']
                      // console.log('Selected: ', zipCodeList[index])
                      setValue('zipCode', zipCodeList[index].zipCode)
                      setValue('city', zipCodeList[index].city)
                      setValue('state', zipCodeList[index].state)
                      setZipCodeList([])
                      setIsZipCode(false)
                    }}
                    onBlur={(ev) => {
                      setZipCodeList([])
                      setIsZipCode(false)
                    }}
                    onChange={(ev) => {
                      setZipCodeList([])
                      setIsZipCode(false)
                    }}
                  >
                    <span>{p1}</span>
                                <span style={{ backgroundColor: 'yellow' }}>
                                  {p}
                                </span>
                                <span>{p2}</span>
                                City: {s.city} State: {s.state}
                    
                  </p>
                  )
                }
                
                )}
              </div>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              type="input"
              placeholder="City"
              readOnly={true}
              name="city"
              {...register('city')}
            />
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              type="input"
              placeholder="State"
              name="state"
              readOnly={true}
              {...register('state')}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Button
              onClick={handleCancel}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "50px",
                float: "right",
                width: "90px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "red", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "50px",
                float: "right",
                marginRight: "13px",
                width: "80px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{
                  color: "#1a82ad",
                  paddingRight: "5px",
                  paddingBottom: "2px",
                }}
              ></SaveOutlinedIcon>
              Save
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={1}>
        <Grid
          container
          spacing={1}
          alignContent="center"
          justifyContent="center"
          style={{
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={2} style={{ width: "300px", alignText: "right" }}>
            <Typography align="right">Practice</Typography>
          </Grid>
          <Grid item xs={3} style={{ width: "300px" }}>
            <Controller
              name="UserPractice"
              control={control}
              render={({ field }) => (
                <SuiSelect
                  {...field}
                  onChange={(e) => {
                    setPracticeIDError(false);
                    setUserPractice(e.value);
                    setDuplicateError(false);
                    setPracticeID(e.value);
                  }}
                  options={practicesList}
                />
                // <ReactSelect
                //   onChange={(e) => setUserPractice(e.value)}
                //   options={practicesList}
                // />
              )}
            />
          </Grid>
          <Grid item xs={1} style={{ width: "300px" }}>
            <Typography>Provider</Typography>
          </Grid>
          <Grid item xs={3} style={{ width: "300px" }}>
            <Controller
              name="provider_id"
              {...register("provider_id")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  {...field}
                  default
                  value={providers.filter(function (option) {
                    return option.value === getValues("provider_id");
                  })}
                  onChange={(value) => {
                    setValue("provider_id", value.value);
                    setUserProvider(value.value);
                    setProviderIdError(false);
                  }}
                  options={providers}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} style={{ width: "300px" }}>
            <Button
              onClick={handleUserPractice}
              sx={{
                width: 200,
                float: "right",
                marginRight: "50px",
              }}
              disabled={submitting}
              variant="gradient"
              color="info"
              size="small"
              style={{
                width: "70px",
                height: "33px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
              // color="warning"
              // size="small"

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <AddOutlinedIcon
                fontSize="medium"
                style={{
                  color: "#1a82ad",
                  paddingRight: "4px",
                  paddingBottom: "2px",
                }}
              ></AddOutlinedIcon>
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignContent="left"
          justifyContent="left"
          style={{
            // paddingTop: '5px',
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            {" "}
            {practiceIdError ? (
              <Typography
                color="error"
                style={{
                  fontSize: "14px",
                  marginTop: "15px",
                  marginLeft: "5px",
                }}
              >
                Please Select Practice!
              </Typography>
            ) : null}
            {duplicateError ? (
              <Typography
                color="error"
                style={{
                  fontSize: "14px",
                  marginTop: "15px",
                  marginLeft: "5px",
                }}
              >
                Practice Already Added! Please Select Another
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={5}>
            {" "}
            {providerIdError ? (
              <Typography
                color="error"
                style={{
                  fontSize: "14px",
                  marginTop: "15px",
                  marginLeft: "5px",
                }}
              >
                Please Select Provider!
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 400, width: "100%", marginTop: "50px" }}>
            {rows === null || rows.length === 0 ? null : (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sx={gridRowStyle}
              />
            )}
          </div>
        </Grid>
      </TabPanel>
    </TabContext>
  );
}

export default UserRegistration;
