/* eslint-disable object-shorthand */
// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import PatientCareTeam from './PatientCareTeam'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import SuiSelect from 'components/SuiSelect'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'
import Button from '@mui/material/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { styled } from '@mui/material/styles'

const history = createBrowserHistory({ forceRefresh: true })
// var providersRedux = []
var allProvidersRedux = []

const Input = styled('input')({
  display: 'none',
})
const fields = [
  'id',
  'careTeamId',
  'patientId',
  'role',
  'providerId',
  'userName',
  'lastName',
  'firstName',
  'mIddleInitial',
  'dob',
  'ssn',
  'phoneNo',
  'relationship',
  'responsiblities',
  'practiceId',
  'inactive',
  'createdDate',
  'photoUrl',
  'base64',
  'updatedDate',
  'createdBy',
  'updatedBy',
  'clientCreatedDate',
  'clientModifiedDate',
]
const defValues = {
  id: 0,
  careTeamId: '',
  patientId: '',
  role: '',
  providerId: '',
  userName: '',
  lastName: '',
  firstName: '',
  mIddleInitial: '',
  dob: '',
  ssn: '',
  phoneNo: '',
  relationship: '',
  responsiblities: '',
  practiceId: '',
  inactive: false,
  createdDate: '',
  photoUrl: '',
  base64: '',
  updatedDate: '',
  createdBy: '',
  updatedBy: '',
  clientCreatedDate: '',
  clientModifiedDate: '',
}
function AddMember(props) {
  const location = useLocation()
  const readOnly = props.readOnly
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [apiError, setApiError] = useState('')
  const [pData, setPData] = useState('')
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )
  const allProvidersRedx = useSelector(
    (state) => (state.allProviders.loading ? [] : state.allProviders.NAMES),
    shallowEqual
  )
  const [allProviders, setAllProviders] = useState([])
  console.log(allProviders,'allProviders')
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    allProvidersRedux = []

    // allProvidersRedux.push({
    //   label: '-----------------',
    //   value: '',
    // })
    // console.log('reduxxx', providersRedx)
     allProvidersRedx.forEach((field) => {
      allProvidersRedux.push({
        label: field.lastName + ', ' + field.firstName,
        value: field.id,
      })
    })

    setAllProviders(allProvidersRedux)
    //     if (dtt_userType != 'Admin')
    //     {
    //       setValue('providerId', providersRedux[0].value)
    //     }
    // console.log('provideredux',providersRedux[0].value)

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  // useEffect(() => {
  //   // POST request using axios inside useEffect React hook
  //   providersRedux = []
  //   // console.log('reduxxx', providersRedx)
  //   providersRedux.push({
  //     label: '-----------------',
  //     value: '',
  //   })
  //   providersRedx.forEach((field) => {
  //     providersRedux.push({
  //       label: field.name,
  //       value: field.id,
  //     })
  //   })

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [{ providersRedx }])
  const [refresh, setRefresh] = useState(false)
  const validationSchema = Yup.object({
    // lastName: Yup.string().required('LastName is required'),
    // firstName: Yup.string().required('FirstName is required'),
  })
  const [lastNameError, setLastNameError] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const Patientid = parseInt(props.patientId)
  const onSubmit = (data) => {
    setApiError('')
    if (
      getValues('providerId') <= 0 ||
      getValues('providerId') == null ||
      getValues('providerId') == undefined
    ) {
      if (
        (getValues('lastName') <= 0 ||
          getValues('lastName') == null ||
          getValues('lastName') == undefined) &&
        (getValues('firstName') <= 0 ||
          getValues('firstName') == null ||
          getValues('firstName') == undefined)
      ) {
        setLastNameError(true)
        return
      } else {
        setLastNameError(false)

        setSubmitting(true)
        const postData = data
        console.log('CareTeamId: ', props.careTeamId)
        if (props.careTeamId > 0) {
          postData.careTeamId = props.careTeamId
        } else {
          postData.patientId = Patientid
        }

        console.log('Data: ', postData)
        // console.log(JSON.stringify(postData, null, 2))
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/CareTeamMembers/addCareTeamMember`,
            postData,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.id) {
              props.cancelModal(false)
              setSubmitting(true)
              alert.success('Record saved successfully.', {
                type: 'success',
                onClose: () => {
                  if (Patientid != null || Patientid.length > 0) {
                    props.refresh()
                  } else {
                    history.push('/CareTeam')
                  }
                },
              })
            } else {
              setSubmitting(false)
              setApiError(response.data)
            }
          })
          .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      }
    } else {
      setSubmitting(true)
      const postData = data
      console.log('CareTeamId: ', props.careTeamId)
      if (props.careTeamId > 0) {
        postData.careTeamId = props.careTeamId
      } else {
        postData.patientId = Patientid
      }

      console.log('Data: ', postData)
      // console.log(JSON.stringify(postData, null, 2))
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/CareTeamMembers/addCareTeamMember`,
          postData,
          {
            headers,
          }
        )
        .then((response) => {
          if (response.data.id) {
          props.cancelModal(false)
          setSubmitting(true)
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              if (Patientid != null || Patientid.length > 0) {
                props.refresh()
              } else {
                history.push('/CareTeam')
              }
            },
          })}
          else
          {
            setSubmitting(false)
            setApiError(response.data)
          }
        })
        .catch((error) => {
          setSubmitting(false)
          alert.success(`Error ${error.message}`, {
            type: 'error',
          })
        })
    }
  }
  var id = props.careTeamMemberId
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true

    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/CareTeamMembers/getCareTeamMembersById?careTeamMemberId=${id}`,
          { headers }
        )
        // .get(
        //   `${process.env.REACT_APP_API_URL}/CareTeamMembers/getCareTeamMembers?careTeamId=1`,
        //   {
        //     headers,
        //   }
        // )
        .then((response) => {
          console.log('response.data: ', response.data)
          setPData(response.data)
          if (
            isComponentMounted &&
            response.data !== null &&
            response.data.id !== ''
          ) {
            fields.forEach((field) => setValue(field, response.data[field]))
          } else fields.forEach((field) => setValue(field, ''))
          setPhone(response.data.phoneNo)
          setssn(response.data.ssn)
          if (response.data.photoUrl) {
            // console.log('URL: ',`${process.env.REACT_APP_DOCUMENT_URL}`)
            var Url = `${process.env.REACT_APP_DOCUMENT_URL}/${response.data.photoUrl}`
            console.log('URL: ', Url)
            setImages(Url)
          }
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    if (id !== undefined && id > 0) fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [id])
  const [phone, setPhone] = React.useState()
  const [ssn, setssn] = React.useState()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [images, setImages] = React.useState(null)
  //// Delete Pic Code
  const deleteimg = () => {
    setImages(null)
    setValue('photoUrl', '')
  }
  /// Add Image Code
  const onImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    convertBase64(file)
    // console.log("64 image",base64)
    // console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    }).then((users) => {
      setValue('base64', users)
    })
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F0FFFF',
    border: '2px solid #black',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: 'bold' }}
          >
            Doing This Togehter
          </SuiTypography>
          <Grid container columnSpacing={1}>
            <Grid item xs={12}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
                style={{ fontWeight: 'normal' }}
              >
                Do you want to delete this member?
              </SuiTypography>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} sm={12}>
            <Button
              onClick={handleClose}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '30px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '90px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: 'red', paddingRight: '5px' }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              onClick={() =>
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/CareTeamMembers/addCareTeamMember`,

                    {
                      id: pData.id,
                      careTeamId: pData.careTeamId,
                      patientId: pData.patientId,
                      role: pData.role,
                      providerId: pData.providerId,
                      userName: pData.userName,
                      lastName: pData.lastName,
                      firstName: pData.firstName,
                      mIddleInitial: pData.mIddleInitial,
                      dob: pData.dob,
                      ssn: pData.ssn,
                      phoneNo: pData.phoneNo,
                      relationship: pData.relationship,
                      responsiblities: pData.responsiblities,
                      practiceId: pData.practiceId,
                      inactive: true,
                      createdDate: pData.createdDate,
                      updatedDate: pData.updatedDate,
                      createdBy: pData.createdBy,
                      updatedBy: pData.updatedBy,
                      clientCreatedDate: pData.clientCreatedDate,
                      clientModifiedDate: pData.clientModifiedDate,
                      photoUrl: pData.photoUrl,
                    },
                    {
                      headers,
                    }
                  )
                  .then((response) => {
                    props.cancelModal(false)
                    setSubmitting(true)
                    alert.success('Record Deleted successfully.', {
                      type: 'success',
                      onClose: () => {
                        props.refresh()
                      },
                    })
                  })
              }
              variant="gradient"
              disabled={readOnly == true ? true : submitting}
              style={{
                marginTop: '30px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: 'auto',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
              // color="warning"
              // size="small"

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <DeleteOutlinedIcon
                fontSize="medium"
                style={{
                  color: 'red',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              ></DeleteOutlinedIcon>
              Yes
            </Button>
          </Grid>
        </Box>
      </Modal>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
      >
        <Grid style={{ display: 'none' }}>
          <Grid item xs={6} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              CareTeam Id
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Patient Id
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: '300px' }}>
            <SuiInput
              placeholder="Please enter careTeamId"
              name="careTeamId"
              label="careTeamId"
              fullWidth
              margin="dense"
              {...register('careTeamId')}
            />
          </Grid>
          <Grid item xs={6} style={{ width: '300px' }}>
            <SuiInput
              placeholder="Please enter patientId"
              name="patientId"
              label="patientId"
              fullWidth
              margin="dense"
              {...register('patientId')}
            />
          </Grid>
        </Grid>
        <p style={{ color: 'red', fontSize: '14px' }}>{apiError}</p>

        <Grid container xs={12}>
          <Grid xs={5}></Grid>
          <Grid xs={2}>
            <label htmlFor="icon-button-file">
              <Input
                id="icon-button-file"
                type="file"
                onChange={onImageChange}
              />
              <Avatar
                sx={{ bgcolor: '#FCBE13' }}
                src={images}
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: '100px',
                }}
              >
                <AddAPhotoIcon
                  fontSize="large"
                  style={{ color: 'black', marginBottom: '5px' }}
                ></AddAPhotoIcon>
              </Avatar>
            </label>
          </Grid>
          {images && images.length > 0 ? (
            <Button
              onClick={deleteimg}
              variant="gradient"
              disabled={readOnly == true ? true : false}
              // disabled={submitting}
              style={{
                marginLeft: '20px',
                width: '70px',
                height: '33px',
                marginTop: '20px',
                color: 'black',
                fontSize: '14px',
              }}
              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <DeleteOutlinedIcon
                fontSize="medium"
                style={{ color: 'red', marginBottom: '5px' }}
              ></DeleteOutlinedIcon>
              Remove
            </Button>
          ) : null}
          <Grid item xs={5}></Grid>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Provider
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Responsibilities
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <Controller
            name="providerId"
            {...register('providerId')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                default
                value={allProvidersRedux.filter(function (option) {
                  return option.value === getValues('providerId')
                })}
                onChange={(value) => {
                  setValue('providerId', value.value)
                }}
                options={allProviders}
              />
              // <SuiSelect
              //   {...field}
              //   default
              //   value={providersRedux.filter(function (option) {
              //     return option.value === getValues('providerId')
              //   })}
              //   onChange={(value) => {
              //     setValue('providerId', value.value)
              //   }}
              //   options={providersRedux}
              // />
            )}
          />
          {/* <SuiInput
            placeholder="Please enter providerId"
            name="providerId"
            label="providerId"
            fullWidth
            margin="dense"
            {...register('providerId')}
          /> */}
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <Controller
            name="responsiblities"
            {...register('responsiblities')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                style={{ overflow: 'auto' }}
                value={{ label: getValues('responsiblities') }}
                onChange={(value) => setValue('responsiblities', value.label)}
                options={[
                  { value: 'PCP', label: 'PCP' },
                  { value: 'BHCM', label: 'BHCM' },
                  {
                    value: 'PsychiatryConsultant',
                    label: 'Psychiatry Consultant',
                  },
                  { value: 'Counselor', label: 'Counselor' },
                  { value: 'MedicalAssistant', label: 'Medical Assistant' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            )}
          />
        </Grid>
        {getValues('providerId') ? null : (
          <>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
              >
                Last Name
              </SuiTypography>
            </Grid>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
              >
                First Name
              </SuiTypography>
            </Grid>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiInput
                placeholder="Please enter lastName"
                name="lastName"
                label="lastName"
                fullWidth
                margin="dense"
                {...register('lastName')}
                //  error={errors.lastName}
              />
              {lastNameError ? (
                <p style={{ color: 'red' }}>{'Name is Required'}</p>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiInput
                placeholder="Please enter firstName"
                name="firstName"
                label="firstName"
                fullWidth
                margin="dense"
                {...register('firstName')}
                // error={errors.firstName}
              />
              {lastNameError ? (
                <p style={{ color: 'red' }}>{'Name is Required'}</p>
              ) : (
                ''
              )}
            </Grid>

            {/* <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            DOB
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            SSN
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            type="date"
            placeholder="Please enter dob"
            name="dob"
            label="dob"
            fullWidth
            margin="dense"
            {...register('dob')}
          />
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
        <InputMask
          mask="999-99-9999"
        disabled={false}
        value={ssn}
        style={{ height: '30px' }}
        onChange={(e) => {
          setValue("ssn",e.target.value)
          setssn( e.target.value)
        }
        }
      >
        {() => <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter SSN"
               />

              }
      </InputMask>
          {/* <SuiInput
            placeholder="Please enter ssn"
            name="ssn"
            label="ssn"
            fullWidth
            margin="dense"
            {...register('ssn')}
          /> */}
            {/* </Grid> */}
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
              >
                Phone No
              </SuiTypography>
            </Grid>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
              >
                Relationship
              </SuiTypography>
            </Grid>
            <Grid item xs={6} style={{ width: '300px' }}>
              <InputMask
                mask="+1 999-999-9999"
                disabled={false}
                value={phone}
                style={{ height: '30px' }}
                onChange={(e) => {
                  setValue('phoneNo', e.target.value)
                  setPhone(e.target.value)
                }}
              >
                {() => (
                  <SuiInput
                    id="outlined-basic"
                    fullWidth
                    margin="dense"
                    placeholder="Enter phoneNo"
                  />
                )}
              </InputMask>
              {/* <SuiInput
            placeholder="Please enter phoneNo"
            name="phoneNo"
            label="phoneNo"
            fullWidth
            margin="dense"
            {...register('phoneNo')}
          /> */}
            </Grid>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiInput
                placeholder="Please enter relationship"
                name="relationship"
                label="relationship"
                fullWidth
                margin="dense"
                {...register('relationship')}
              />
            </Grid>
          </>
        )}
        {/* <Grid item xs={12} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Responsibilities
          </SuiTypography>
        </Grid>
        <Grid item xs={12} style={{ width: '300px' }}>
          <Controller
            name="responsiblities"
            {...register('responsiblities')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                style={{ overflow: 'auto' }}
                value={{ label: getValues('responsiblities') }}
                onChange={(value) => setValue('responsiblities', value.label)}
                options={[
                  { value: 'PCP', label: 'PCP' },
                  { value: 'BHCM', label: 'BHCM' },
                  {
                    value: 'PsychiatryConsultant',
                    label: 'Psychiatry Consultant',
                  },
                  { value: 'Counselor', label: 'Counselor' },
                  { value: 'MedicalAssistant', label: 'Medical Assistant' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            )}
          />
          {/* <SuiInput
            placeholder="Please enter responsiblities"
            name="responsiblities"
            label="responsiblities"
            multiline
            fullWidth
            margin="dense"
            {...register('responsiblities')}
          /> */}
        {/* </Grid> */}
        <Grid item xs={12} style={{ width: '300px' }}>
          <Button
            onClick={() => {
              props.cancelModal
                ? props.cancelModal(false)
                : history.push('/CareTeam')
            }}
            variant="gradient"
            disabled={submitting}
            style={{
              float: 'right',
              marginTop: '10px',
              marginLeft: 'auto',
              float: 'right',
              width: '90px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
            // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
          >
            <CancelOutlinedIcon
              fontSize="medium"
              style={{ color: 'red', paddingRight: '5px' }}
            ></CancelOutlinedIcon>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="gradient"
            disabled={readOnly == true ? true : submitting}
            style={{
              marginTop: '10px',
              float: 'right',
              marginRight: '13px',
              width: '80px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
            // color="warning"
            // size="small"

            // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
          >
            <SaveOutlinedIcon
              fontSize="medium"
              style={{
                color: '#1a82ad',
                paddingRight: '5px',
                paddingBottom: '2px',
              }}
            ></SaveOutlinedIcon>
            Save
          </Button>
          {id && Patientid ? (
            <Button
              onClick={handleOpen}
              variant="gradient"
              disabled={readOnly == true ? true : submitting}
              style={{
                marginTop: '10px',
                float: 'right',
                marginRight: '13px',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
              // color="warning"
              // size="small"

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <DeleteOutlinedIcon
                fontSize="medium"
                style={{ color: 'red', marginBottom: '3px' }}
              ></DeleteOutlinedIcon>
              Delete
            </Button>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  )
}
export default AddMember
