/* eslint-disable object-shorthand */
// @mui material components
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Grid, Typography } from '@mui/material'
import axios from 'axios'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
import { createBrowserHistory } from 'history'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { confirmAlert } from 'react-confirm-alert' // Import
import { useLocation } from 'react-router-dom'
 import EditIcon from '@mui/icons-material/Edit'
 import DeleteIcon from '@mui/icons-material/Delete'
 import AddIcon from '@mui/icons-material/Add'
import SuiBox from 'components/SuiBox'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import typography from 'assets/theme/base/typography'
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import DialogTitle from '@mui/material/DialogTitle';
const history = createBrowserHistory({ forceRefresh: true })

const fields = [
  'id',
  'patientId',
  'medicalNotes',
  'patientNoteId',
  'practiceId',
  'inactive',
  'createdDate',
  'updatedDate',
  'createdBy',
  'updatedBy',
  'clientCreatedDate',
  'clientUpdatedDate',
]
const defValues = {
  id: 0,
  patientId: 0,
  medicalNotes: '',
  patientNoteId: '',
  practiceId: 0,
  inactive: '',
  createdDate: '',
  updatedDate: '',
  createdBy: '',
  updatedBy: '',
  clientCreatedDate: '',
  clientUpdatedDate: '',
}
const styleAddMember = {
  position: 'absolute',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: '#F0FFFF',
  border: '2px solid black',
  boxShadow: 24,
  p: 2,
  // overflow: 'scroll',
  // height:'350ppx'

  }
function MedicalNotes(props) {
  const location = useLocation()
  const readOnly=props.readOnly
  const alert = useAlert()
  // console.log('props', props)
  const [submitting, setSubmitting] = useState(false)
  const [dataReceived, setdataReceived] = useState(false)
  const [openNote, setOpenNote] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [templates, setTemplates] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [checkBox, setCheckBox] = useState(false) 
const [patientNotes, setPatientNotes] = useState('') 
  const [templatesNotes, setTemplatesNotes] = useState('') 

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const [open, setOpen] = useState(false);
  const [IdDelete, setSdS] = useState(false);
console.log(IdDelete,'vvs1')
const [deleteIndex, setDeleteIndex] = useState(null); // Added state for the delete index
console.log(deleteIndex,'deleteIndex')
const deleteById = (indexToDelete) => {
  if (indexToDelete !== null) {
    const postData = {
      id: IdDelete.id,
      inactive: false,
      medicalNotes:IdDelete.medicalNotes,
      patientId:IdDelete.patientId,
      patientNoteId:IdDelete.patientNoteId,
      practiceId:IdDelete.practiceId,
      createdDate:IdDelete.createdDate,
      updatedDate:IdDelete.updatedDate,
      createdBy:IdDelete.createdBy,
      updatedBy:IdDelete.updatedBy,
      clientCreatedDate:IdDelete.clientCreatedDate,
      clientUpdatedDate:IdDelete.clientUpdatedDate,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/PatientMedicalNotes`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    setOpen(false);
  }
};

  const validationSchema = Yup.object({
    medicalNotes: Yup.string().required('Please Enter Notes!'),
  })
  const handleCloseAddMember = () => {setOpenModal(false)
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const isTemplate=(data)=>{
    setSubmitting(true)
    // const postData = data
      var checkedRows=templates.filter(templates => templates.inactive==true)
      console.log('isChecked:', checkedRows)
      checkedRows.forEach(element => {
        element.patientNoteId = parseInt(props.patientnoteId)
 element.inactive=false
element.patientId=parseInt(props.patientId)
element.id=0
 element.isTemplate=false
      });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/PatientMedicalNotesList`,
        checkedRows,
        {
          headers,
        }
      )
      .then((response) => {
        setRefresh(!refresh)
        setOpenModal(false)
        setSubmitting(false)
        // console.log('response.data: ', response.data)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
 }
  const onSubmit = (data) => {   
    setSubmitting(true)
    const postData = data

    postData.isNoteReview = false
    postData.patientId = props.patientId
    postData.patientNoteId = props.patientnoteId
    if(props.patientId==0){
      postData.isTemplate = true
    }
    // console.log('Data: ', postData)
    // console.log(JSON.stringify(postData, null, 2))
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/PatientMedicalNotes`,
        postData,
        { headers }
      )
      .then((response) => {
        setRefresh(!refresh)
         alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            setOpenNote(false)
            setSubmitting(false)
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  const onDelete = (data) => {    

  setSubmitting(true)

  postData.isNoteReview = false
  postData.patientId = props.patientId
  postData.patientNoteId = props.patientnoteId
  if(props.patientId==0){
    postData.isTemplate = true
  }
  // console.log('Data: ', postData)
  // console.log(JSON.stringify(postData, null, 2))
  
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/PatientMedicalNotes`,
      postData,
      { headers }
    )
    .then((response) => {
      setRefresh(!refresh)
       alert.success('Record saved successfully.', {
        type: 'success',
        onClose: () => {
          setOpenNote(false)
          setSubmitting(false)
        },
      })
    })
    .catch((error) => {
      setSubmitting(false)
      alert.success(`Error ${error.message}`, {
        type: 'error',
      })
    })
}
  useEffect(() => {
    var Url=''
    // POST request using axios inside useEffect React hook
    if(props.patientId!=0){
      Url=`id=
      ${
        props.patientnoteId !== undefined ? props.patientnoteId : 0
      }`}
      else {
        Url=
        `isTemplate=${true}`
      }
      
      console.log('Patient Note: ',Url) 
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/PatientMedicalNotesByNotesId?${Url}`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('response.data: ', response.data)
        if(props.patientId!=0){
          var res=response.data
          if(res && res.length>0){
            res=res[0]
            console.log('Patient Note: ',res)
            if (res) {
              fields.forEach((field) => setValue(field, res[field]))
            }
            setdataReceived(true) 
            setPatientNotes(res)          
        }
      }
          else { 
        setTemplatesNotes(response.data)
          }
          
        // setPostData(response.data)
        // setCheckBox(true)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  function handleCancel() {
    history.push('/PatientSearch')
  }
 const GetTemplates=()=>{
  setOpenModal(true)
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
       axios
        .get(
          `${
            process.env.REACT_APP_API_URL}/PatientMedicalNotes/PatientMedicalNotesByNotesId?isTemplate=${true}`,
          {
            headers,
          }
        )
        .then((response) => {
          if(response.data){
          // if (isComponentMounted) {
          //   fields.forEach((field) => setValue(field, response.data[field]))
          // }
         // if(response.data.isTemplate==true){
          
          setRefresh(!refresh)
          setTemplates(response.data)
        // }
        // else{
        //   setTemplatesNotes(null)
        // }
      }
      
    })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
      }
   
  return (
    <>
    <Modal
        open={openModal}
        onClose={handleCloseAddMember}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
     
      >
        <Box sx={styleAddMember} style={{overflow:'scroll', height:'500px'}}>
          {/* <CancelOutlinedIcon
                fontSize="medium"
                style={{  paddingRight: '5px' }}
              ></CancelOutlinedIcon> */}
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: 'bold' }}
          >
            Templates
          </SuiTypography>
          <CancelOutlinedIcon
            onClick={() => { setOpenModal(false)
              setRefresh(!refresh)
            }}
            variant="contained"
            style={{
              float: 'right',
              color: 'red',
            }}
          ></CancelOutlinedIcon>

          <Grid container columnSpacing={1}>
          {templates != null && templates.length > 0
        ? templates.map((s, index) => {
            return (
           <>
           <Grid
           container
           xs={12}
           sm={12}
           md={12}
           lg={12}
           style={{ width: '100%', marginTop: '5px' }}
           bgcolor={'rgb(161,230,246)'}
         >
           <Grid item spacing={0} xs={6} sm={1} md={1} lg={1}>
          
              
                    <Checkbox style={{marginBottom:'4px',marginLeft:'5px'}}
                                 onChange={(e) => {
                                  s.inactive=e.target.checked
                                                                  // setCheckBox(e.target.checked)
                                                                }}
                            />
             
           </Grid>
           <Grid item spacing={1} xs={1} sm={1} md={1} lg={1}>
             <Typography
               style={{
                 height: '20px',
                 paddingBottom: '5px',
                 fontSize: '14px',
                 marginRight: '10px',
               }}
               component="label"
               variant="caption"
               fontWeight="bold"
               color="#2188FE"
             >
               Notes:
             </Typography>
             </Grid>
             <Grid item spacing={1} xs={10} sm={10} md={10} lg={10}>

             <Typography
               style={{
                //  height: '20px',
                 paddingTop: '7px',
                 fontSize: '14px',
                //  marginRight: '10px',
               }}
              //  component="label"
              //  variant="caption"
               fontWeight="bold"
               color="#333333"
               dangerouslySetInnerHTML={{ __html: s.medicalNotes }}
             >
             </Typography>
           </Grid>
         </Grid>
         </>
            )
          })
          
        : 'No Record Found'}
        {templates != null && templates.length > 0?
        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                              onClick={() => {
                                setOpenModal(false)
                                // item.isChecked = false
                                // setError('')
                                // setDate('')
                              }}
                              variant="gradient"
                              disabled={submitting}
                              // color="black"
                              // size="small"
                              style={{
                                marginTop: '5px',
                                float: 'right',
                                marginLeft: '20px',
                                width: '90px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                              }}
                              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                            >
                              <CancelOutlinedIcon
                                fontSize="medium"
                                style={{ color: 'red', paddingRight: '5px' }}
                              ></CancelOutlinedIcon>
                              Cancel
                            </Button>
                            
                            <Button
                    onClick={isTemplate}
                    variant="gradient"
                              disabled={submitting}
                              style={{
                                marginTop: '5px',
                                float: 'right',
                                marginLeft: 'auto',
                                // width: '80px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px', 
                              }}
                              // color="warning"
                              // size="small"

                              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                            >
                              <SaveOutlinedIcon
                                fontSize="medium"
                                style={{
                                  color: '#1a82ad',
                                  paddingRight: '5px',
                                  paddingBottom: '2px', 
                                }}
                              ></SaveOutlinedIcon>
                              Apply Template
                            </Button>
                          </Grid>
                          :''}
          </Grid>
        </Box>
      </Modal>



      <Grid
        container
        // spacing={1}
        style={{
          paddingTop: '10px',
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={6}
          lg={12}
          style={{ width: '100%', height: '40px', paddingLeft: '10px' }}
          bgcolor={'rgb(161,230,246)'}
        >
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            fontWeight="bold"
          >
            Notes
            </SuiTypography>
            {dataReceived ? (
              
              
            <EditIcon
          color="black"
          style={{ marginRight: '5px', float: 'right',marginTop: '5px',cursor:'pointer' }}
          onClick={(event) => {
            setOpenNote((current) => !current)
           }}
        ></EditIcon>
        ):(
          <AddIcon
          color="black"
          style={{ marginRight: '5px', float: 'right',marginTop: '5px',cursor:'pointer' }}
          onClick={(event) => {
            fields.forEach((field) => setValue(field, ''))
            setValue('id',0)  
            setValue('practiceId',0)   
                    setOpenNote((current) => !current)
           }}
        ></AddIcon>

        )}
            {/* <EditIcon
              color="black"
              style={{ marginRight: '5px', float: 'right', marginTop: '5px',cursor:'pointer' }}
              variant="gradient"
              onClick={(event) => {
                setOpenNote(current => !current)
                //setdataReceived(false)
                // var data = patientDiagnosis[index]
                // fields.forEach((field) => setValue(field, data[field]))
                // console.log('index: ', patientDiagnosis[index])
              }}
            ></EditIcon> */}
            {props.patientId==0?'':
               <SuiButton
                onClick={GetTemplates}
                variant="gradient"
                color="info"
                // disabled={readOnly==true?true:false}
                size="small"
                style={{
                  width: '80px',
                  float: 'right',
                  // display: 'inline-block',
                  marginRight: '8px',
                  marginTop:'3px'
                }}
                // startIcon={
                //   <img
                //     src={AddIcon}
                //     style={{ marginTop: '-3px', marginRight: '-6px' }}
                //   />
                // }
              >
                Templates
              </SuiButton>}
          
        </Grid>
        {patientNotes != null && dataReceived == true && props.patientId > 0 ? (
  <Grid item xs={12} style={{ paddingLeft: '20px',paddingRight:'20px', width: '100%' }}>
    {dataReceived ? (
      !openNote ? (
        <div
          style={{
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            maxWidth: '100%',
          }}
          dangerouslySetInnerHTML={{ __html: patientNotes.medicalNotes }}
        />
      ) : null
    ) : (
      'No notes found.'
    )}
  </Grid>
) : (
  ''
)}


        <Grid item xs={12}>
          <SuiBox mb={3}>
            {openNote == true ? (
              <Grid
                container
                spacing={1}
                style={{
                  paddingTop: '10px',
                  paddingBottom: '30px',
                  paddingLeft: '50px',
                  paddingRight: '50px',
                  borderRadius: '20px',
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {
                    <CKEditor
                      editor={ClassicEditor}
                      data={getValues('medicalNotes')}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setValue('medicalNotes', data)
                      }}
                      onBlur={(event, editor) => {
                        // console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        // console.log('Focus.', editor)
                      }}
                    />
                  }
                  <p style={{ color: 'red', fontSize: '13px' }}>
                    {errors.medicalNotes?.message}
                  </p>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                    onClick={(event) => {
                      setOpenNote(false)
                    }}
                    variant="gradient"
                    disabled={submitting}
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <CancelOutlinedIcon
                      fontSize="medium"
                      style={{ color: 'red', paddingRight: '3px' }}
                    ></CancelOutlinedIcon>
                    Cancel
                  </Button>
                  {props.patientId==0? (
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="gradient"
                    disabled={readOnly==true?true:submitting}
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      // width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <SaveOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#0a9ff0', paddingRight: '5px' }}
                    ></SaveOutlinedIcon>
                    Save As Template
                  </Button>):( 
                             <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="gradient"
                    // disabled={readOnly==true?true:submitting}
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <SaveOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#0a9ff0', paddingRight: '5px' }}
                    ></SaveOutlinedIcon>
                    Save
                  </Button>)}

                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {
            props.patientId==0?
            templatesNotes != null && templatesNotes.length > 0
        ? templatesNotes.map((s, index) => {
            return (
           <>
           <Grid
           container
           xs={12}
           sm={12}
           md={12}
           lg={12}
           style={{ width: '100%', marginTop: '5px' }}
          //  bgcolor={'rgb(161,230,246)'}
         >
           <Grid item spacing={1} xs={1} sm={1} md={1} lg={1}>
             <Typography
               style={{
                 height: '20px',
                 paddingBottom: '5px',
                 fontSize: '14px',
                 marginRight: '10px',
                 marginLeft:'10px'
               }}
               component="label"
               variant="caption"
               fontWeight="bold"
               color="#2188FE"
             >
              {/* 0000 */}
               Notes:
             </Typography> </Grid>
             <Grid item spacing={1} xs={10} sm={10} md={10} lg={10}>

             <Typography
               style={{
                //  height: '20px',
                 paddingTop: '7px',
                 fontSize: '14px',
                //  marginRight: '10px',
               }}
              //  component="label"
              //  variant="caption"
               fontWeight="bold"
               color="#333333"
               dangerouslySetInnerHTML={{ __html: s.medicalNotes }}
             >
         
             </Typography>
           </Grid>
           <Grid item spacing={1} xs={6} sm={1} md={1} lg={1}>
           <>
           {/* 11111 */}
      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          cursor: 'pointer',
        }}
        color="red"
        onClick={(event) => {
          setDeleteIndex(index); // Set the index before opening the dialog

          setSdS(s)
          setOpen(true);
        }}
      ></DeleteIcon>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {background:'#cef5f0',
          boxShadow:'none',
          borderRadius:'8px'
          },
       }}  
      >


<div style={{borderRadius:'8px',width:'22rem',background:'white'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle  style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>This action cannot be undone.</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={()=>{deleteById(s)}} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button onClick={() => setOpen(false)} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
</div>

      
      </Dialog>
    </>
                <EditIcon
                  style={{
                    marginRight: '5px',
                    float: 'right',
                    marginTop: '5px',cursor:'pointer'
                  }}
                  color="black"
                  onClick={(event) => {
                    setOpenNote(true) 
                    setSubmitting(false)
                    var data = templatesNotes[index]
                    fields.forEach((field) => setValue(field, data[field]))
                  }}
                ></EditIcon>
              </Grid>
          
         </Grid>
         </>
            )
          })
          
        : ' No templates found.':null }
          </SuiBox>
        </Grid>
      </Grid>
    </>
  )
}
export default MedicalNotes
