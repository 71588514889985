import React, { useEffect, useReducer, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Button, MenuItem, Typography } from "@material-ui/core";
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Fab from "@material-ui/core/Fab";
import Container from "@material-ui/core/Container";
import Radio from "@material-ui/core/Radio";
import Input from "@material-ui/core/Input";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import FormLabel from "@material-ui/core/FormLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createBrowserHistory } from "history";
import axios from "axios";
import Box from "@mui/material/Box";
import SuiSelect from "components/SuiSelect";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PermIdentity from "@mui/icons-material/PermIdentity";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import Grid from "@material-ui/core/Grid";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import moment from "moment";
import Moment from "moment";
import Demographics from "Modules/Demographics";


const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: 20,
    bottom: 20,
  },
  container: {
    width: 900,
    // height: '100%',
    height: "fit-content",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // overflow:'auto',
    padding: "10px",
    margin: "auto",
    // [theme.breakpoints.down('sm')]: {
    //   width: '100vw',
    //   // height: '100vh',
    // },
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F0FFFF",
  border: "2px solid #black",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};
const styleDetails = {
  width: 1000,
  height: "95%",
  bgcolor: "#F0FFFF",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 30,
  right: 0,
  margin: "auto",
  marginTop: "10px",
  paddingBottom: "10px",
  padding: "20px",
  overflow: "auto",
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const history = createBrowserHistory({ forceRefresh: true });

const fields = [
  "id",
  "patientId",
  "providerId",
  "patientName",
  "appointmentDate",
  "insuranceId",
  "appointmentStatus",
  "appointmentReason",
  "startTime",
  "endTime",
  "duration",
  "roomId",
  "comment",
  "Deleted",
  "practiceId",
  "createdDate",
  "updatedDate",
  "createdBy",
  "updatedBy",
  "clientCreatedDate",
  "clientUpdatedDate",
];
const defValues = {
  id: 0,
  patientId: "",
  patientName: "",
  providerId: "",
  appointmentDate: "",
  insuranceId: "",
  appointmentStatus: "",
  appointmentReason: "",
  roomId: "",
  comment: "",
  startTime: "",
  endTime: "",
  duration: "",
  Deleted: false,
  practiceId: "",
  createdDate: "",
  updatedDate: "",
  createdBy: "",
  updatedBy: "",
  clientCreatedDate: "",
  clientUpdatedDate: "",
};
// function handleCancel() {
//   history.push("/careteam");
//   setTimeout(() => {
//     this.setState({ loading: false, visible: false });
// }, 3000);
// }
let providersRedux = [];

const NewAppointments = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [pData, setPData] = useState("");
  const [providerId, setProviderId] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [timerangeerror, settimerangeerror] = useState(false);
  const [endTimeerror, setendTimeerror] = useState(false);

  const [timeFrom, setTimeFrom] = useState(props.timeFrom);
  const [timeTo, setTimeTo] = useState(props.timeTo);
  const [durationPatient, setDuration] = useState(props.duration);

  const errorMesage = {
    color: "red",
    fontSize: "13px",
    marign: "0px",
  };

  // console.log('propsData: ', props)
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setOpen(false);
    setOpenDetails(false);
  };
  const handleOpen = () => setOpen(true);
  // const handleDetails = React.useCallback((e) => {
  //   console.log('id:',e)
  //     history.push({
  //     pathname: `/patientsregistration`,
  //     state: { patientId: e, selectedTabAppointment: 'selectedTabAppointment' },
  //   })
  //   window.location.reload(false)
  //    // toast({
  //   //   message: 'View file',
  //   // })
  // }, [])
  const handleDetails = () => {
    setOpenDetails(true);
    setPatientID("");
  };

  const location = useLocation();
  const pId = props.pId 
  const _patientId = props.patientId ||(location.state ? location.state.patientId  : null);

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [submittingg, setSubmittingg] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [emailSent, setemailSent] = useState(false);
  const [response, setResponse] = useState("");
  const [insurance, setInsurance] = useState("");
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  const validationSchema = Yup.object({
    // patientName: Yup.string().required("Please Select Patient."),
    startTime: Yup.string().required("Please Select Start Time."),
    endTime: Yup.string().required("Please Select End Time."),
  
    // appointmentStatus: Yup.string().required(
    //   'Please Select Appointment Status.'
    // ),
    // appointmentReason: Yup.string().required('Please Enter Appointment Reason.'),
    providerId: Yup.string().required("Please Select Provider"),
    appointmentDate: Yup.string().required('Please Select Appointment Date'),

    //   .min(3, "Password must be at least 3 characters")
    //   .max(15, "Password must not exceed 15 characters"),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    fields,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  );
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = [];
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      });
    });
    console.log("providersRedux", providersRedux);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ providersRedx }]);


  useEffect(() => {
    if (smsSent || emailSent) {
      const timeout = setTimeout(() => {
        setSmsSent(false);
        setemailSent(false);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [smsSent, emailSent]);
  const [error, seterror] = useState(null);
  const [error1, seterror1] = useState(null);
  const [error2, seterror2] = useState(null);
  const [error3, seterror3] = useState(null);
  const onSubmit = (data) => {
 
    if (data.appointmentDate == '') {
     console.log("month is required")
 
      return;
    }

 
console.log("Form dataappointmentStatus:", data.endTime);

    console.log("Form data:", data);
    if (timerangeerror) {
      return;
    }
    if (data.appointmentStatus==='') {
      seterror1('Please Select Appointment Status')
    }else{seterror1('')
     
    }
    if (data.appointmentDate==='Invalid date') {
      seterror('Please Select Start Time')
    }else{
      seterror('')
    }
    if (data.startTime==='Invalid date') {
      seterror2('Please Select Start Time')
    }else{
     
    }
    if (data.endTime==='Invalid date') {
      seterror3('Please Select End Time')
    }else{
     
    }
    if (timerangeerror) {
      return;
    }



    setSubmitting(true);
    setResponse("");
    const postData = data;
    postData.startTime = `${data.appointmentDate} ${data.startTime}`;
    postData.endTime = `${data.appointmentDate} ${data.endTime}`;
    postData.patientId = `${_patientId}`

  
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`,
        postData,
        { headers }
      )
      .then((response) => {
        applyRefresh()
        if (response.data.id) {
          setSubmitting(false);
          props.canclemodel(false);
          props.getRefresh(true)
          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {

            },
          });
        } else {
          setResponse(response.data);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  
    const id=props.eId
  console.log("id", props.id)
  // console.log('starttime', startTime)
  const Date = props.sTime;
  const appointmentId = props.sendingId;
  console.log(appointmentId,'appointmentId,,')
  const endTime = props.eTime;
  // console.log('endtime',endTime);

  let dateYMD = moment(Date).format("L");
  dateYMD = Moment(dateYMD).format("YYYY-MM-DD");
  const sdate = dateYMD;

  const [sTime, setSTime] = useState(null);
  const [eTime, setETime] = useState(null);
  const [patientID, setPatientID] = React.useState("");

  const duration = (event) => {
    // console.log('drr', event)
    const startTimeDuration = Moment(`01/01/2022 ${sTime}`);
    const endTimeDuration = moment(`01/01/2022 ${eTime}`);
    const ms = endTimeDuration.diff(startTimeDuration, "minutes");
    setValue("duration", ms);
  };

  useEffect(() => {
    if (appointmentId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL
          }/PatientAppointment/getPatientAppointmentById?id=${appointmentId !== undefined ? appointmentId : 0
          }`,
          {
            headers,
          }
        )

        .then((response) => {

          fields.forEach((field) => setValue(field, response.data[field]));
          setSTime(response.data.startTime);
          setETime(response.data.endTime);
          setPData(response.data);
          setPatientID(response.data.patientId);
          setPatientSelected(true);
          // console.log('data::', response.data)
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    else {
      setValue("appointmentDate", sdate);
      const timeS = Moment(Date).format("HH:mm:ss");
      setSTime(timeS);
      setValue("startTime", timeS);
      const timeE = moment(endTime).format("HH:mm:ss");
      setETime(timeE);
      setValue("endTime", timeE);
      duration();
    }
  }, []);
  useEffect(() => {
    if (patientID) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientInsurance/PatientInsuranceNames?patientid=${_patientId}`,
          {
            headers,
          }
        )

        .then((response) => {
          if (response.data.id) {
            setInsurance(response.data.name);
            setProviderId(response.data.providerId);
            setValue("providerId", response.data.providerId);
          } else {
            setInsurance("");
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [patientID]);

  const [openn, setOpenn] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openn && options.length === 0;
  const [patientData, setPatientData] = React.useState([]);
  const [patient, setPatient] = React.useState(false);
  const [patientSelected, setPatientSelected] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [searchText, setSearchText] = useState("");

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);

    const sendData = {
      id: 0,
      lastName: value,
      firstName: "",
      ssn: "",
      status: "",
      cellPhone: "",
      address: "",
      dob: "",
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then((response) => {
        // console.log('responses: ', response.data)

        // const patientName = response.data;
        setPatientData(response.data);
        setPatient(true);

        // console.log('patient: ', response.data)

        // setOptions(Object.keys(countries).map(key => key.zipCode))
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // const response = await fetch(
    //   "https://country.register.gov.uk/records.json?page-size=5000"
    // );

    // const countries = await response.json();
    // setOptions(Object.keys(countries).map(key => countries[key].item[0]));
  };
  const [patientNamee, setPatientNamee] = useState("");
  // console.log('patientNamee:', patientNamee)
  // console.log('evvv:', i)

  // console.log('sdate',sdate)
  const [refresh, setRefresh] = useState(false);
  const applyRefresh = () => {
    setRefresh(!refresh);
    console.log("refresh:", refresh);
  };
  console.log(applyRefresh,'applyRefresh')
  React.useEffect(() => {
    if (!openn) {
      setOptions([]);
    }
  }, [openn]);
  React.useEffect(() => {
    duration();
  }, [sTime, eTime]);
  const zipCodeListStyle = {
    cursor: "pointer",
    hover: {
      background: "#3b3b3b !important",
      color: "rgba(5, 152, 236, 0.637) !important",
    },
    color: "green",
    selection: {
      background: "#D7624E",
      color: "white",
    },
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;

  

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  const Fname = localStorage.getItem('firstName')
  const PatientLastName = localStorage.getItem('lastNames');
  const Duration = localStorage.getItem('duration');
  console.log(Fname, 'Fname')
  
  return (
    <>
      <Modal menuPortalTarget={document.body}
        open={openDetails}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDetails} >
          <form className={classes.form} autoComplete="off">
            <CancelOutlinedIcon
              onClick={() => {
                setOpenDetails(false);
                // props.refresh()
              }}
              variant="contained"
              style={{
                float: "right",
                color: "red",
              }}
            />
            <h3 style={{ backgroundColor: "Primary" }}>Demographics</h3>
            <Demographics
              patientId={patientID || null}
              cancelmodel={setOpenDetails}
            />
          </form>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: "bold" }}
          >
            Doing This Togehter
          </SuiTypography>
          <Grid container columnSpacing={1}>
            <Grid item xs={12}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
                style={{ fontWeight: "normal" }}
              >
                Do you want to delete this event?
              </SuiTypography>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} sm={12}>
            <Button
              onClick={handleClose}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "20px",
                width: "90px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
            // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "red", paddingRight: "5px" }}
              />
              Cancel
            </Button>

            <Button
              onClick={() => {
                setSubmitting(true);

                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`,

                    {
                      id: pData.id,
                      patientId: id,
                      patientName: pData.patientName,
                      appointmentDate: pData.appointmentDate,
                      insuranceId: pData.insuranceId,
                      appointmentStatus: pData.appointmentStatus,
                      appointmentReason: pData.appointmentReason,
                      roomId: pData.roomId,
                      comment: pData.comment,
                      startTime: pData.startTime,
                      endTime: pData.endTime,
                      duration: pData.duration,
                      Deleted: "true",
                      practiceId: pData.practiceId,
                      createdDate: pData.createdDate,
                      updatedDate: pData.updatedDate,
                      createdBy: pData.createdBy,
                      updatedBy: pData.updatedBy,
                      clientCreatedDate: pData.clientCreatedDate,
                      clientUpdatedDate: pData.clientUpdatedDate,
                    },
                    {
                      headers,
                    }
                  )
                  .then((response) => {
                    
                    props.canclemodel(false);
                    setSubmitting(true);
                    alert.success("Record Deleted successfully.", {
                      type: "success",
                      onClose: () => {

                      },
                    });
                  });
              }}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "auto",
                width: "80px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
            // color="warning"
            // size="small"

            // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{
                  color: "#1a82ad",
                  paddingRight: "5px",
                  paddingBottom: "2px",
                }}
              />
              Yes
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Grid container >
        {submitting && <CircularProgress />}
        {submitting && <div>Saving Form...</div>}
        <Modal style={{ overflowY: 'scroll', height: 'auto', overflowX: 'hidden' }}
          className={classes.container}
          open
          onClose={() => {
            props.canclemodel(false);

          }}
        >
          <Grid className={classes.container} >
            <form className={classes.form} style={{ marginTop: '2rem', }} autoComplete="off">
              <CancelOutlinedIcon
                onClick={() => {
                  props.canclemodel(false);

                }}
                variant="contained"
                style={{
                  marginTop: '4px',
                  float: "right",
                  color: "red",
                }}
              />
              <h3 style={{ backgroundColor: "Primary" }}>Appointment</h3>
              <p style={{ color: "red", fontSize: "16px" }}>{response}</p>
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
              // style={{ paddingTop: "10px", paddingLeft: "150px", paddingRight: "150px" }}
              >
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Patient Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Appointment Date
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ marginTop: '5px', width: "300px", border: "1px solid", borderRadius: '8px', height: '39px' }}>
                  {PatientLastName}, {Fname}
                  {/* <p style={{ color: "red", fontSize: "13px" }}>
                    {errors.patientName?.message}
                  </p> */}
                </Grid>



                {/* {patient ? (
                    <div
                      className="col-xs-6"
                      style={{
                        padding: "14px 16px",
                        fontSize: "0.875rem",
                        color: "#67748e",
                        borderRadius: "5px",
                        background: "white",
                        textAlign: "justify",
                        textJustify: "inter-word",
                        position: "absolute",
                        zIndex: "99",
                        width: "39%",
                        border: "1px solid #6cb5f9",
                      }}
                    >
                      {patient
                        ? patientData.map((s, index) => {
                          const pat = `${s.lastName}, ${s.firstName}`;
                          const patLower = pat.toLowerCase();
                          const i = patLower.indexOf(searchText);
                          // console.log('pat:', patLower)
                          console.log("searchText:", searchText);
                          // console.log('i:', i)
                          const p1 = pat.substring(0, i);
                          // console.log('p1:', p1)
                          const p = pat.substring(i, searchText.length + i);
                          // console.log('p::::::: ', p)
                          // console.log('p:',p)
                          const p2 = pat.substring(
                            i + p.length,
                            patLower.length
                          );
                          // console.log('p:',p2)
                          return (
                            <p
                              // attribZip={s.zipCode}
                              // attribState={s.state}
                              // attribCity={s.city}
                              // attribIndex={index}
                              style={zipCodeListStyle}
                              onClick={(ev) => {
                                // var sel=ev.target.attributes['attribIndex']
                                // console.log('Selected: ', patientData[index])
                                setValue(
                                  "patientName",
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                );
                                setValue("patientId", patientData[index].id);
                                setPatientID(patientData[index].id);
                                setPatientData([]);
                                setPatientSelected(true);
                                setPatient(false);
                                setPatientNamee(
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                );
                              }}
                              onBlur={(ev) => {
                                setPatientData([]);
                                setPatient(false);
                              }}
                              onChange={(ev) => {
                                setPatientData([]);
                                setPatientSelected(false);
                                setPatient(false);
                                setPatientNamee("");
                              }}
                            >
                              <span>{p1}</span>
                              <span style={{ backgroundColor: "yellow" }}>
                                {p}
                              </span>
                              <span>{p2}</span>
                            </p>
                          );
                        })
                        : null}
                    </div>
                  ) : (
                    ""
                  )} */}
                {/* </Grid> */}
                {/* <Grid item xs={1}>
                  {patientSelected ? (
                    <Button
                      onClick={() => setOpenDetails(true)}
                      // onClick={()=>{handleDetails(patientID)}}
                      variant="text"
                      disabled={submitting}
                      style={{
                        // marginTop: '10px',
                        float: "left",
                        // marginLeft: '8px',
                        width: "50px",
                        height: "35px",
                        // backgroundColor: '#FCBE13',
                        color: "#2EA4D6",
                        fontSize: "14px",
                      }}
                    // color="warning"
                    // size="small"

                    // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <PermIdentity
                        fontSize="small"
                        style={{ color: "blue", marginBottom: "3px" }}
                      />
                      Details
                    </Button>
                  ) : (
                    <Button
                      onClick={handleDetails}
                      // onClick={()=>{handleDetails()}}
                      variant="text"
                      disabled={submitting}
                      style={{
                        // marginTop: '10px',
                        float: "left",
                        // marginLeft: '8px',
                        width: "50px",
                        height: "35px",
                        // backgroundColor: '#FCBE13',
                        color: "#2EA4D6",
                        fontSize: "14px",
                      }}
                    // color="warning"
                    // size="small"

                    // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <AddOutlinedIcon
                        fontSize="small"
                        style={{ color: "blue", marginBottom: "3px" }}
                      />
                      New
                    </Button>
                  )}
                </Grid> */}
                {/* 1111 */}
                <Grid item xs={6}>
                <SuiInput
                    type="date"
                    // value={sdate}
                    placeholder="Please enter Appointment Date"
                    name="appointmentDate"
                    fullWidth
                    margin="dense"
                    {...register("appointmentDate")}
                    // error={errors.appointmentDate}
                  />
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {error}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Insurance
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Provider
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <SuiInput
                    value={insurance}
                    name="insuranceId"
                    label="insuranceId"
                    disabled
                    fullWidth
                    margin="dense"
                    {...register("insuranceId")}
                  // error={errors.insuranceId}
                  />

                  <p style={{ color: "red" }}>{errors.alias?.message}</p>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="providerId"
                    {...register("providerId")}
                    control={control}
                    render={({ field }) => (
                      <SuiSelect
                        isClearable
                        {...field}
                        error={errors.providerId}
                        default
                        value={providersRedux.filter((option) => {
                          if (providerId) {
                            return option.value === providerId;
                          }
                          return option.value === getValues("providerId");
                        })}
                        onChange={(value) => {
                          setProviderId("");
                          setValue("providerId", value.value);
                        }}
                        options={providersRedux}
                      />
                    )}
                  />
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.providerId?.message}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Appointment Reason
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Appointment Status
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <SuiInput
                    placeholder="Please enter Appointment Reason"
                    name="appointmentReason"
                    fullWidth
                    margin="dense"
                    {...register("appointmentReason")}
                  // error={errors.appointmentReason}
                  />
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {/* {errors.appointmentReason?.message} */}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="gender"
                    {...register("appointmentStatus")}
                    control={control}
                    // error={errors.appointmentStatus}
                    render={({ field }) => (
                      <SuiSelect
                        value={{ label: getValues("appointmentStatus") }}
                        onChange={(value) =>
                          setValue("appointmentStatus", value.label)
                        }
                        options={[
                          { value: "CheckedIn", label: "CheckedIn" },
                          { value: "CheckedOut", label: "CheckedOut" },
                          { value: "Confirmed", label: "Confirmed" },
                          { value: "Late", label: "Late" },
                          { value: "No-Show", label: "No-Show" },
                          { value: "ReSheduled", label: "ReSheduled" },
                          { value: "Scheduled", label: "Scheduled" },
                          { value: "Canceled", label: "Canceled" },
                        ]}
                      />
                    )}
                  />
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {error1}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Appointment Start
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Appointment End
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <SuiInput
                    type="time"
                    placeholder="Please enter Appointment Start Time"
                    name="startTime"
                    value={sTime}
                    // min="09:00"
                    // max="17:00"
                    min={timeFrom}
                    max={timeTo}
                    required
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      if (value <= timeFrom || value >= timeTo) {
                        settimerangeerror(true);
                      } else {
                        setValue("startTime", e.currentTarget.value);
                        setSTime(e.currentTarget.value);
                        console.log("Start Time: ", e.currentTarget.value);
                        settimerangeerror(false);
                      }
                    }}
                    fullWidth
                    margin="dense"
                    // {...register('startTime')}
                    error={errors.startTime}
                  />

                  {timerangeerror ? (
                    <p style={errorMesage}>
                      Please select Time From : {timeFrom} To : {timeTo}
                    </p>
                  ) : null}
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {error2}
                  </p>
                </Grid>

                <Grid item xs={6}>
                  <SuiInput
                    type="time"
                    placeholder="Please enter Appointment End Time"
                    name="endTime"
                    value={eTime}
                    fullWidth
                    // min="09:00"
                    // max="17:00"
                    min={timeFrom}
                    max={timeTo}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      if (value <= timeFrom || value >= timeTo) {
                        setendTimeerror(true);
                      } else {
                        setValue("endTime", e.currentTarget.value);
                        setETime(e.currentTarget.value);
                        console.log("end Time: ", e.currentTarget.value);
                        setendTimeerror(false);
                      }
                    }}
                    margin="dense"
                    error={errors.endTime}
                  />
                  {endTimeerror ? (
                    <p style={errorMesage}>
                      Please select Time From : {timeFrom} To : {timeTo}
                    </p>
                  ) : null}
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {error3}
                  </p>
                </Grid>

                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Appointment Durations
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="label" style={{ fontWeight: 500 }}>
                    Room
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                <SuiInput
                    style={{ height: "10px", padding: "10px" }}
                    readOnly
                    disabled
                    type="text"
                    name="duration"
                    label="minutes"
                    margin="dense"
                    {...register("duration")}
                  // error={errors.startTime}
                  />

                  {/* <p style={{ color: "red" }}>{errors.responsibility?.message}</p> */}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ marginTop: "8px" }}>minutes</Typography>

                  {/* <p style={{ color: "red" }}>{errors.responsibility?.message}</p> */}
                </Grid>

                <Grid item xs={1}>
                
                  {/* <p style={{ color: "red" }}>{errors.responsibility?.message}</p> */}
                </Grid>
             
                <Grid item xs={6}>
                  <SuiInput
                    placeholder="Please enter Room"
                    name="roomId"
                    fullWidth
                    margin="dense"
                    {...register("roomId")}
                    error={errors.roomId}
                  />

                </Grid>
                <Grid container xs={12} >
                  <Grid item xs={6} >  <Typography component="label" style={{ fontWeight: 500, float: 'left' }}>
                    Message
                  </Typography> </Grid>
                  <Grid item xs={6} > </Grid>
                  <Grid item xs={6} >



                    <Box
                      sx={{
                        width: '100%',
                        maxWidth: '100%',
                      }}
                    >

                      <Textarea
                        name="comment"

                        margin="dense"
                        {...register("comment")}
                        error={errors.comment}
                        style={{ width: '100%', }} aria-label="maximum height" minRows={3} placeholder="Your message here" />
                    </Box>
                  </Grid>


                  <Grid item style={{ marginTop: '2.4rem', }} xs={6} >
                    <Button
                      onClick={() => {
                        props.canclemodel(false);

                      }}
                      variant="gradient"
                      disabled={submitting}
                      style={{
                        // float: "right",
                        marginRight: '3rem',
                        marginTop: "10px",
                        marginLeft: "auto",
                        float: "right",
                        width: "90px",
                        height: "35px",
                        backgroundColor: "#FCBE13",
                        color: "black",
                        fontSize: "14px",
                      }}
                    // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CancelOutlinedIcon
                        fontSize="medium"
                        style={{ color: "red", paddingRight: "5px" }}
                      />
                      Cancel
                    </Button>
                    {pData.appointmentStatus != "CheckedOut" ? (
               <Button
               type="submit"
               onClick={handleSubmit(onSubmit)}
               variant="gradient"
               disabled={submitting}
               style={{
                 marginTop: "10px",
                 float: "right",
                 marginRight: "13px",
                 width: "80px",
                 height: "35px",
                 backgroundColor: "#FCBE13",
                 color: "black",
                 fontSize: "14px",
               }}
             >
               <SaveOutlinedIcon
                 fontSize="medium"
                 style={{
                   color: "#1a82ad",
                   paddingRight: "5px",
                   paddingBottom: "2px",
                 }}
               />
               Save
             </Button>
             
                  
                    
                    ) : null}

                    {appointmentId && pData.appointmentStatus != "CheckedOut" ? (
                      <Button
                        onClick={handleOpen}
                        variant="gradient"
                        disabled={submitting}
                        style={{
                          marginTop: "10px",
                          float: "right",
                          marginRight: "13px",
                          width: "80px",
                          height: "35px",
                          backgroundColor: "#FCBE13",
                          color: "black",
                          fontSize: "14px",
                        }}
                      // color="warning"
                      // size="small"

                      // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                      >
                        <DeleteOutlinedIcon
                          fontSize="medium"
                          style={{ color: "red", marginBottom: "3px" }}
                        />
                        Delete
                      </Button>
                    ) : null}

                    {appointmentId && pData.appointmentStatus != "CheckedOut" ? (
                      <>
                        <Button
                          onClick={() => {
                            setSubmitting(true);
                            axios
                              .get(
                                `${process.env.REACT_APP_API_URL
                                }/PatientAppointment/ResendPatientAppointmentSMS?AppointmentId=${appointmentId !== undefined ? appointmentId : 0
                                }`,
                                {
                                  headers,
                                }
                              )
                              .then((response) => {
                                // props.canclemodel(false)
                                setSubmitting(false);
                                setSmsSent(true);
                                alert.success("Sms sent Successfully.", {
                                  type: "success",
                                });
                                console.log("Sms send Successfully");
                              })
                              .catch((error) => {
                                console.error("There was an error!", error);
                                setSubmitting(false);
                                alert.success(`Error ${error.message}`, {
                                  type: "error",
                                });
                              });
                          }}
                          variant="gradient"
                          disabled={submitting}
                          style={{
                            float: "right",
                            marginTop: "10px",
                            // marginLeft: '10PX',
                            float: "left",
                            width: "130px",
                            height: "35px",
                            backgroundColor: submitting ? "gray" : "#FCBE13",
                            color: submitting ? "black" : "black",
                            fontSize: "14px",
                          }}
                        >
                          Resend SMS
                        </Button>

                        {/* {smsSent  && (
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          Resend SMS Successfully
                        </div>
                      )} */}

                        <Button
                          onClick={() => {
                            setSubmittingg(true);
                            axios
                              .get(
                                `${process.env.REACT_APP_API_URL
                                }/PatientAppointment/ResendPatientAppointmentEmail?AppointmentId=${appointmentId !== undefined ? appointmentId : 0
                                }`,
                                {
                                  headers,
                                }
                              )
                              .then((response) => {
                                // props.canclemodel(false)
                                setemailSent(true);
                                setSubmittingg(false);
                                alert.success("Email sent Successfully.", {
                                  type: "success",
                                });
                                console.log("Email send Successfully");
                              })
                              .catch((error) => {
                                console.error("There was an error!", error);
                                setSubmittingg(false);
                                alert.success(`Error ${error.message}`, {
                                  type: "error",
                                });
                              });
                          }}
                          variant="gradient"
                          disabled={submittingg}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginLeft: "10PX",
                            width: "140px",
                            height: "35px",
                            // backgroundColor: '#FCBE13',
                            backgroundColor: submittingg ? "gray" : "#FCBE13",
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          Resend Email
                        </Button>

                        {smsSent && (
                          <div style={{ textAlign: "center", marginTop: "10px" }}>
                            Resend SMS Successfully
                            {/* <SuiTypography>Resend SMS Successfully</SuiTypography> */}
                          </div>
                        )}

                        {emailSent && (
                          <div style={{ textAlign: "center", marginTop: "10px" }}>
                            Resend Email Successfully
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}</Grid>
                </Grid>
                <Grid item xs={12}>

                </Grid>
              </Grid>
            </form>
          </Grid>
        </Modal>
        {/* <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            This is a success message!
          </Alert>
        </Snackbar> */}
      </Grid>
    </>
  );
};
export default NewAppointments;
