import * as React from 'react'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { confirmAlert } from 'react-confirm-alert'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Timelogeidth from './Timelogeidth'
import { createBrowserHistory } from 'history'
import customStyle from '../assets/customStyle.css'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import Button from '@mui/material/Button'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import LetteredAvatar from 'react-lettered-avatar'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import { useAlert } from 'react-alert'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

// import MUIDataTable from "mui-datatables";

const history = createBrowserHistory({ forceRefresh: true })
const Input = styled('input')({
  display: 'none',
})
const headers = {
  Authorization: `Bearer  ${localStorage.getItem('dtt_token')}`,
}
// const openTimelogeidth = (event, id) => {
//   history.push({
//     pathname: `/Timelogeidth`,
//     state: { Timelogid: id },
//   })
//   window.location.reload(false)
// }

function Timelog(props) {
  // console.log('props timelog:', props)
  const readOnly = props.readOnly
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const alert = useAlert()
  const [value, setValue] = React.useState('1');
  const [newNote, setnewNote] = useState(false)
  const [openNote, setOpenNote] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [logid, setlogid] = useState(0)
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedCell1, setSelectedCell1] = useState(null);
console.log(selectedCell1,'selectedCell1')
  const [loading, setLoading] = useState(false);



  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const postData = {
      name: selectedCell1.row.name,
      lastname: selectedCell1.row.lastname,
      userName: selectedCell1.row.userName,
      id: selectedCell1.row.id,
      inactive: true,
      patientId: selectedCell1.row.patientId,
      practiceId: selectedCell1.row.practiceId,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/addPatientTimeLog`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    handleClose();
  };

console.log('selectedCelsetSelectedCell(cell)l',selectedCell)
  const handleClickOpen = (cell) => {
    setSelectedCell(cell);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setSelectedCell(null);
    setOpenAlert(false);
  };
  const handleDeleteById = () => {
    if (!selectedCell) {
      // Handle error or show a message as needed
      console.error('No cell selected for deletion.');
      return;
    }
  
    const postData = {
      name: selectedCell.row?.name,
      lastname: selectedCell.row?.lastname,
      userName: selectedCell.row?.userName,
      id: selectedCell.row?.id,
      inactive: true,
      patientId: selectedCell.row?.patientId,
      practiceId: selectedCell.row?.practiceId,
    };
  
    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientTimeLog/addPatientTimeLog`, postData, {
        headers,
      })
      .then((response) => {
        alert.success('Record Deleted successfully.');
        // Handle success, e.g., show a success message
        console.log('Record Deleted successfully.');
        setRefresh(!refresh)
        setLoading(false);
        
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error:', error);
        setLoading(false);
      });
  
    handleCloseAlert();
  };
  
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setOpenNote(false)
            setlogid(cellValues.row.id)
            // openTimelogeidth(event, cellValues.row.id)
          }}
        >{`${cellValues.row.id}`}</SuiButton>
      ),
    },
    {
      field: 'fullUserName',
      headerName: 'User',
      headerClassName: 'super-app-theme--header',
      flex: 0.6,
      renderCell: (cellValues) => (
        <div>
          {cellValues.row.url ? (
            <Grid container>
              <Grid item xs={1} style={{ paddingRight: '10px' }}>
                <img
                  src={`${process.env.REACT_APP_DOCUMENT_URL}/${(
                    cellValues.row.url
                  )}`}
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '100px',
                    padding: '2px',
                  }}
                />
                {/* <Avatar
                  sx={{ bgcolor: '#FCBE13' }}
                  src={cellValues.row.url}
                  style={{
                    height: 36,
                    width:36,
                    // borderRadius: '100px',
                  }}
                >
                </Avatar> */}
              </Grid>
              <Grid item xs={10}>
                <Typography
                  style={{
                    marginLeft: '28px',
                    marginTop: '7px',
                    fontSize: '13px',
                  }}
                >
                  {cellValues.row.fullUserName}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={1} style={{ paddingRight: '10px' }}>
                <LetteredAvatar
                  name={cellValues.row.fullUserName.charAt(0)}
                  size={33}
                ></LetteredAvatar>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  style={{
                    marginLeft: '28px',
                    marginTop: '7px',
                    fontSize: '13px',
                  }}
                >
                  {cellValues.row.fullUserName}
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      ),
    },

    {
      field: 'userName',
      headerName: 'Email',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'patientProgram',
      headerName: 'Program',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'timeSpent',
      headerName: 'Total Time',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          style={{ cursor: 'text', textAlign: 'center' }}
          color="black"
        >{`${cellValues.row.timeSpent}`}</Button>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      renderCell: (cell) => (
        <>
        {
          <EditIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            cursor: 'pointer',
          }}
          color="black"
          onClick={(event) => {
            setOpenNote(false)
            setlogid(cell.row.id)
            // openTimelogeidth(event, cellValues.row.id)
          }}
        ></EditIcon>
        }
         {
              <>
  <Button  onClick={()=>{handleOpen()
                  setSelectedCell1(cell)
                }}>
        <DeleteIcon
          color="red"
          style={{
            marginRight: '2rem',
            float: 'left',
            marginTop: '5px',
            color:'red'
          }}
        />
      </Button>


        
              <Dialog open={open} 
                aria-labelledby="responsive-dialog-title"
                BackdropComponent={Backdrop}
                BackdropProps={{
                  invisible: true,
                }}
                PaperProps={{
                  style: {background:'#cef5f0',
                  boxShadow:'none',
                  borderRadius:'8px'
                  },
               }} 
              
              onClose={handleClose}>

<div style={{borderRadius:'8px'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={handleDelete} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={handleClose} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


          
              </Dialog>
            </>
        }
          
        
        </>
      ),
    },
  ]
  const columns1 = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      flex: 0.2,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setOpenNote(false)
            setlogid(cellValues.row.id)
            // openTimelogeidth(event, cellValues.row.id)
          }}
        >{`${cellValues.row.id}`}</SuiButton>
      ),
    },
    {
      field: 'fullUserName',
      headerName: 'User',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => (
        <div>
          {cellValues.row.url ? (
            <Grid container>
              <Grid item xs={1} style={{ paddingRight: '10px' }}>
                <img
                  src={`${process.env.REACT_APP_DOCUMENT_URL}/${(
                    cellValues.row.url
                  )}`}
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '100px',
                    padding: '2px',
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  style={{
                    marginLeft: '28px',
                    marginTop: '7px',
                    fontSize: '13px',
                  }}
                >
                  {cellValues.row.fullUserName}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={1} style={{ paddingRight: '10px' }}>
                <LetteredAvatar
                  name={cellValues.row.fullUserName.charAt(0)}
                  size={33}
                ></LetteredAvatar>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  style={{
                    marginLeft: '28px',
                    marginTop: '7px',
                    fontSize: '13px',
                  }}
                >
                  {cellValues.row.fullUserName}
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      ),
    },

    {
      field: 'userName',
      headerName: 'Email',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'patientProgram',
      headerName: 'Program',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'timeSpent',
      headerName: 'Total Time',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          style={{ cursor: 'text', textAlign: 'center' }}
          color="black"
        >{`${cellValues.row.timeSpent}`}</Button>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      renderCell: (cell) => (
        <>
  
        {
          <EditIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            cursor: 'pointer',
          }}
          color="black"
          onClick={(event) => {
            setOpenNote(false)
            setlogid(cell.row.id)
            // openTimelogeidth(event, cellValues.row.id)
          }}
        ></EditIcon>
        }
        { <> <Button onClick={() => {handleClickOpen(cell.row)
              setSelectedCell(cell)
        }}>
  <DeleteIcon
    color="red"
    style={{
      marginRight: '5px',
      float: 'right',
      marginTop: '5px',
      color:'red'
    }}
  />
</Button>

<Dialog
  open={openAlert}
  onClose={handleCloseAlert}
  aria-labelledby="responsive-dialog-title"
  BackdropComponent={Backdrop}
  BackdropProps={{
    invisible: true,
  }}
  PaperProps={{
    style: {
      background: '#cef5f0',
      boxShadow: 'none',
      borderRadius: '8px',
    },
  }}
>
  <div style={{ borderRadius: '8px' }}>
    <ErrorOutlineIcon
      style={{ marginLeft: '125px', fontSize: '100px', color: 'orange' }}
    ></ErrorOutlineIcon>
    <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
    <h5 style={{ textAlign: 'center' }}>This action cannot be undone.</h5>
    <DialogContent>
      <DialogContentText>
        <Grid style={{ marginLeft: '90px' }}>
          <Button
            onClick={handleDeleteById}
            style={{ backgroundColor: 'red', color: 'white' }}
          >
            YES
          </Button>
          <Button
            onClick={handleCloseAlert}
            style={{
              backgroundColor: 'blue',
              color: 'white',
              marginLeft: '1rem',
            }}
          >
            NO
          </Button>
        </Grid>
      </DialogContentText>
    </DialogContent>
  </div>
</Dialog>

                
                </>
          //   <DeleteIcon
          //   style={{
          //     marginRight: '5px',
          //     float: 'right',
          //     marginTop: '5px',
          //     cursor: 'pointer',
          //     color:'red',
          //   }}
            
          //   onClick={(cellValues) => {
          //     // console.log('Cell: ', cell)

          //     if (readOnly==false) {
          //       confirmAlert({
          //         message: 'Do you want to delete the record.',
          //         closeOnEscape: true,
          //         closeOnClickOutside: true,
          //         overlayClassName: 'overlay-custom-class-name',
          //         buttons: [
          //           {
          //             label: 'Yes',
          //             onClick: () => {
          //               const postData = {
          //                 name: cell.row.name,
          //                 lastname: cell.row.lastname,
          //                 userName: cell.row.userName,
          //                 id: cell.row.id,
          //                 inactive: true,
          //                 patientId: cell.row.patientId,
          //                 practiceId: cell.row.practiceId,
          //               }
          //               axios
          //                 .post(
          //                   `${process.env.REACT_APP_API_URL}/PatientTimeLog/addPatientTimeLog`,
          //                   postData,
          //                   {
          //                     headers,
          //                   }
          //                 )
          //                 .then((response) => {
          //                   setRefresh(!refresh)
          //                   // setOpenNote(false)
          //                   // console.log('response.data: ', response.data
          //                 })
          //                 .catch((error) => {
          //                   console.error('There was an error!', error)
          //                 })
          //             },
          //           },
          //           {
          //             label: 'No',
          //           },
          //         ],
          //       })
          //     } else {
          //       <Typography>One Record cannot be deleted</Typography>
          //     }
          //   }}
          // ></DeleteIcon>
        }
          
        
        </>
      ),
    },
  ]



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    alignContent: 'center',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
  }
  const [rows, setRows] = useState(null)
  const [rows1, setRows1] = useState(null)

  useEffect(() => {
    const postData = {
      patientId: parseInt(props.patientId),
      currentMonth:true
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/Report`,
        postData,
        { headers }
      )
      .then((response) => {
        // setSubmitting(false)
        console.log('response: ', response.data)
        setRows(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [refresh])
  useEffect(() => {
    const postData = {
      patientId: parseInt(props.patientId),
      currentMonth:false
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/Report`,
        postData,
        { headers }
      )
      .then((response) => {
        // setSubmitting(false)
        console.log('response: ', response.data)
        setRows1(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [refresh])
  return (
    <>
      {openNote ? (
        <>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
            <Button
              sx={{ mb: 2, marginRight: '20px' }}
              onClick={(event) => {
                setRefresh(!refresh)
              }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '120px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <CachedOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '2px',
                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button>

            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="CURRENT MONTH" value="1" />
                    <Tab label="HISTORY" value="2" />
                  
                  </TabList>
                </Box>
                <TabPanel value="1"> <div style={{ height: 350, width: '100%' }}>
                {rows === null || rows.length === 0 ? null : (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    headerHeight={37}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={gridRowStyle}
                  />
                )}
              </div>
              </TabPanel>
                <TabPanel value="2"> <div style={{ height: 350, width: '100%' }}>
                {rows1 === null || rows1.length === 0 ? null : (
                  <DataGrid
                    rows={rows1}
                    columns={columns1}
                    headerHeight={37}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={gridRowStyle}
                  />
                )}
              </div>
              </TabPanel>
                
              </TabContext>
            </Box>

            
          </Grid>
          {/* <SuiBox mb={1}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ height: 400, width: '100%' }}
            >
              <div style={{ height: 350, width: '100%' }}>
                {rows === null || rows.length === 0 ? null : (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    headerHeight={37}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={gridRowStyle}
                  />
                )}
              </div>
            </Grid>
          </SuiBox> */}
        </>
      ) : (
        <Grid
          container
          xs={12}
          spacing={1}
          style={{
            paddingTop: '10px',
            borderRadius: '20px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ width: '100%', marginTop: '5px', borderRadius: '20px' }}
            // bgcolor={'rgb(161,230,246)'}
          >
            <Button
              variant="gradient"
              onClick={(event) => {
                setOpenNote(true)
                setnewNote(false)
                setRefresh(!refresh)
              }}
              style={{
                marginLeft: '16px',
                paddingRight: '1px',
                paddingLeft: '0px',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <ArrowBackIosNewOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              ></ArrowBackIosNewOutlinedIcon>
              Back
            </Button>
            {/* <ArrowBackIosIcon
              color="black"
              style={{ marginLeft: '5px', float: 'left', marginTop: '5px' }}
              onClick={(event) => {
                setOpenNote(true)
                setnewNote(false)
              }}
            > </ArrowBackIosIcon> */}
          </Grid>
          {logid ? (
            <Timelogeidth
              Timelogid={logid}
              Backfunction={setOpenNote}
              readOnly={readOnly}
            ></Timelogeidth>
          ) : null}
        </Grid>
      )}
    </>
  )
}

export default Timelog
