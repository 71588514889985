import PdfDocsCard from "./PdfDocsCard";
import { Grid, Typography } from "@mui/material";
import { createBrowserHistory } from "history";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// typescript style
import { useLocation } from "react-router-dom";
import { arSD } from "@mui/material/locale";
import Button from "@mui/material/Button";
// import Pdf from '../Documents/test.pdf'
import { Document, Page } from "react-pdf";
import { confirmAlert } from "react-confirm-alert";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import { Backdrop } from '@mui/material';
import SuiTypography from "components/SuiTypography";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { from } from "stylis";
import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";


export default function ProviderResources(props) {
  const [doc, setDoc] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [activeButton, setActiveButton] = useState(false);
  const [images, setImages] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showScreen, setShowScreen] = useState(false);
  const [activeDocName, setActiveDocName] = useState('');
  //

  const handleCloseScreen = () => {
    setShowScreen(false);
  };

  const handleOpenScreen=() => {
    setShowScreen(true);
  }

  const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(1),
  },
}));


// useEffect(() => {
//   document.addEventListener("mouseout", () => {
//     setShow(false);
//   });
// }, []);

  const styleDetailsPdf = {
    width: 900,
    height: "100%",
    bgcolor: "#F0FFFF",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: "auto",
    marginTop: "0",
    paddingBottom: "10px",
    padding: "0px",
    overflow: "auto",
    zIndex: "10",
    // tabIndex: "-1",
  };

  // const Input = styled('input')({
  //   display: 'block',
  // })
  const fields = [
    "id",
    "sequenceId",
    "userName",
    "documentName",
    "documentUrl",
    "practiceId",
    "deleted",
    "createdBy",
    "updatedBy",
    "createdDate",
    "updatedDate",
    "clientCreatedDate",
    "clientUpdatedDate",
    "base64",
  ];
  const defValues = {
    id: 0,
    sequenceId: "",
    userName: "",
    documentName: "",
    documentUrl: "",
    practiceId: "",
    deleted: "",
    createdBy: "",
    updatedBy: "",
    createdDate: "",
    updatedDate: "",
    clientCreatedDate: "",
    clientUpdatedDate: "",
    base64: "",
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues,
  });
  const location = useLocation();
  const oncall = "";
  const history = useHistory();
  const setDocument = (ar) => {
    setDoc(ar);
  };

  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };

  const style1 = {
    // Draggable:'true',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [file, setFile] = useState(false);
  const [providerNotes, setProviderNotes] = useState([]);
  const [docName, setDocName] = useState("");
  const [refreshAssessment, setrefreshAssessment] = useState(false);
  const classes = useStyles();

  const onSubmit = (data) => {
    setSubmitting(true);
    const postData = data;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/ProviderNotes/addProviderNotes`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            setRefresh(!refresh);
            setSubmitting(false);

            // if (Patientid != null || Patientid.length > 0) {
            //   props.refresh()
            // } else {
            //   history.push('/CareTeam')
            // }
          },
        });
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook

    axios
      .get(`${process.env.REACT_APP_API_URL}/ProviderNotes/getProviderNotes`, {
        headers,
      })
      .then((response) => {
        // console.log('response.data: ', response.data)
        setProviderNotes(response.data);
        setFile(true);
        setDocName("");
        // const Doc = (response.data.documentName)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh]);
  const onPdfChange = (e) => {
    const file = e.target.files[0];
    setDocName(file.name);
    setValue("documentName", file.name)
    convertPdfToBase64(file);
  };
  
  const convertPdfToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
  
      fileReader.onload = () => {
        const base64String = fileReader.result.split(',')[1];
        const fileType = file.type;
        const base64WithFileType = `data:${fileType};base64,${base64String}`;
        resolve(base64WithFileType);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    }).then((base64WithFileType) => {
      setValue('base64', base64WithFileType);
      console.log("base64", base64WithFileType);
    });
  };
  
 
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  // const handleDeleteRecord = (row) => {
  //   console.log('first',row)
  //   console.log('first11',providerNotes)

  //   const postData = {
  //     documentName: row?.documentName,
  //     documentUrl: row?.documentUrl,
  //     id: row?.id,
  //     userName: row?.userName,
  //     practiceId: row?.practiceId,
  //     deleted: true,
  //   };

  //   setSubmitting(true);

  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/ProviderNotes/addProviderNotes`, postData, { headers })
  //     .then((response) => {
  //       setRefresh(!refresh);
  //       setSubmitting(false);
  //       setDocument("");
  //       setSelectedIndex(null);
  //       handleCloseDialog();
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });
  // };

  const handleOpenDialog = (row) => {
    setSelectedRow(row); // Set the selected row
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setSelectedRow(null); // Reset the selected row
    setOpenDialog(false);
  };
  
  const handleDeleteRecord = () => {
    if (!selectedRow) return;
  
    const postData = {
      documentName: selectedRow?.documentName,
      documentUrl: selectedRow?.documentUrl,
      id: selectedRow?.id,
      userName: selectedRow?.userName,
      practiceId: selectedRow?.practiceId,
      deleted: true,
    };
  
    setSubmitting(true);
  
    axios
      .post(`${process.env.REACT_APP_API_URL}/ProviderNotes/addProviderNotes`, postData, { headers })
      .then(() => {
        setRefresh(!refresh);
        setSubmitting(false);
        setSelectedRow(null);
        handleCloseDialog();
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid container xs={12}>
            {/* <Grid xs={4}></Grid> */}
            <Grid xs={6} style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  document.getElementById("choosefile").click();
                }}
                variant="gradient"
                style={{
                  marginTop: "10px",
                  // float: 'center',
                  // marginRight: '13px',
                  width: "300px",
                  height: "35px",
                  marginLeft: "5px",
                  // backgroundColor: '#FCBE13',
                  color: "black",
                  fontSize: "14px",
                }}
                // color="warning"
                // size="small"

                // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <FileUploadIcon
                  fontSize="medium"
                  style={{
                    color: "#1a82ad",
                    paddingRight: "5px",
                    paddingBottom: "2px",
                  }}
                ></FileUploadIcon>
                Upload Doc{" "}
                <SuiTypography style={{ fontSize: "10px" }}>
                  &nbsp;&nbsp;{docName}
                </SuiTypography>
              </Button>

              <label htmlFor="icon-button-file">
                <input
                  id="choosefile"
                  style={{ display: "none" }}
                  type="file"
                  name="upload"
                  accept="application/pdf"
                  onChange={onPdfChange }
                />
              </label>
            </Grid>
            {docName ? (
                      <Grid item xs={2} style={{ textAlign: "center" }}>
                        <Button
                          onClick={handleSubmit(onSubmit)}
                          variant="gradient"
                          disabled={docName ? submitting : true}
                          style={{
                            marginTop: "10px",
                            // float: 'center',
                            marginRight: "13px",
                            width: "80px",
                            height: "35px",
                            // marginLeft: '5px',
                            backgroundColor: "#FCBE13",
                            color: "black",
                            fontSize: "14px",
                          }}
                          
                        >
                          <SaveOutlinedIcon
                            fontSize="medium"
                            style={{
                              color: "#1a82ad",
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          ></SaveOutlinedIcon>
                          Save
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
            <Grid xs={4} style={{ textAlign: "center", marginTop: "8px" }}>
              <Button
                style={{ backgroundColor: "black" }}
                onClick={() => setShow(!show)}
                // onClick={() => setHide(!hide)}
              >
                {show ? (
                  <ExpandLessIcon
                    style={{ color: "white", backgroundColor: "black" }}
                  ></ExpandLessIcon>
                ) : (
                  <ExpandMoreIcon
                    style={{ color: "white", backgroundColor: "black" }}
                  ></ExpandMoreIcon>
                )}
              </Button>
            </Grid>
            <Grid>
            </Grid>
            {show && (
              <div>
                <Grid style={{ marginTop: "10px" }}>
                  <>
                  
                    <Grid item xs={5}></Grid>
                    {providerNotes != null && providerNotes.length > 0
                      ? providerNotes.map((row, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{ textAlign: "center" }}
                            >
                              {row.documentName ? (
                                <Grid container>
                                  <Grid item xs={1} sm={1} md={1} lg={1}>
                                    <SuiTypography
                                      style={{ marginTop: "12px" }}
                                    >
                                      {index + 1}:
                                    </SuiTypography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    style={{ textAlign: "left" }}
                                  >
                                    <Button
                                      variant={
                                        selectedIndex == index
                                          ? "contained"
                                          : "link"
                                      }
                                      size="md"
                                      style={{
                                        Gridor: "black",
                                        // height: '90px',
                                        // width: '92%',
                                        fontSize: 20,
                                        margin: "10px",
                                        fontWeight: "bold",
                                        fontFamily: "initial",
                                        // margin: "5px",
                                        padding: "5px",
                                        backgroundGridor: "#FEB313",
                                        textAlign: "left",
                                        justifyContent: "left",
                                      }}
                                      onClick={() => {

                                        setActiveDocName(row.documentName)
                                        // setActiveButton(props.value)
                                        handleOpenScreen();
                                        setSelectedIndex(index),
                                          row.documentUrl
                                            ? //
                                            
                                              //   window.open(props.file)
                                              // <Pdf source={props.file}
                                              // ></Pdf>
                                              setDocument(
                                                `${
                                                  axios
                                                  .get(`${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${row.documentUrl}`, {
                                                    headers
                                                  })
                                                  .then(response => {
                                                    // setisLoading(false)
                                                    setDoc(response.data)
                                                  })
                                                  .catch(error => {
                                                    console.error('There was an error!', error)
                                                  })
                                                }/${(row.documentUrl)}`
                                              )
                                            : // history.push({
                                              //   pathname: `/Pdf`,
                                              //   state: { source: props.file },
                                              // })
                                              confirmAlert({
                                                message:
                                                  "Please Contact Administrator! Document Not Found.",
                                                closeOnEscape: true,
                                                closeOnClickOutside: true,
                                                // overlayClassName: 'overlay-custom-class-name'
                                                buttons: [
                                                  {
                                                    label: "Ok",
                                                  },
                                                ],
                                              });
                                      }}
                                    >
                                      {row.documentName}
                                    </Button>
                                  </Grid>
                                  <Grid
  item
  xs={1}
  sm={1}
  md={1}
  lg={1}
  style={{
    textAlign: "center",
    marginTop: "15px",
  }}
>
  {row.documentName && row.userName !== -1 ? (
    <>
      <Button
        disabled={submitting}
        onClick={() => handleOpenDialog(row)} // Pass the current row
        variant="gradient"
        style={{
          marginLeft: "5px",
          width: "20px",
          height: "33px",
          color: "red",
          fontSize: "14px",
        }}
      >
        <DeleteOutlinedIcon
          fontSize="medium"
          style={{
            color: "red",
            marginBottom: "5px",
          }}
        />
      </Button>

      <Dialog
        open={openDialog}
        aria-labelledby="responsive-dialog-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        onClose={handleCloseDialog}
      >
        <div style={{ borderRadius: "8px" }}>
          <ErrorOutlineIcon
            style={{ marginLeft: "125px", fontSize: "100px", color: "orange" }}
          />
          <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
          <h5 style={{ textAlign: "center" }}>
            This action cannot be undone.
          </h5>
          <DialogContent>
            <DialogContentText>
              <Grid style={{ marginLeft: "90px" }}>
                <Button
                  onClick={handleDeleteRecord}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  YES
                </Button>
                <Button
                  onClick={handleCloseDialog}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    marginLeft: "1rem",
                  }}
                >
                  NO
                </Button>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </>
  ) : null}
</Grid>

                                </Grid>
                              ) : null}
                            </Grid>
                          );
                        })
                      : "No Record Found"}
                  </>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    
 
{showScreen ? (
         <Modal
          open={handleOpenScreen}
          closeOnEscape={false}
          closeOnClickOutside={false}
          onClose={() => {
            setShowScreen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
       
       
          <Box>
             <Draggable bounds={{ left: -400, top: 0, right: 400, bottom: 500 }}>
              <Box sx={styleDetailsPdf}>
                <form className={classes.form} autoComplete="off">


                    <>
                      <span> </span>
                      <span style={{ fontSize: "16px" }}>
                        {activeDocName}
                        {/* Document Name */}
                        </span>
                    </>

                  <CancelOutlinedIcon
                    onClick={() => {
                      setShowScreen(false);
                    }}
                    variant="contained"
                    style={{
                      float: "right",
                      color: "red",
                      marginTop:'5px',
                      marginLeft:"5px",
                    }}
                  ></CancelOutlinedIcon>

                  {doc ? (
                    <Grid
                      container
                      columnSpacing={1}
                      alignContent="center"
                      justifyContent="center"
                    >
                      <div
                        id="iframe_container"
                        style={{
                          position: "absolute",
                          top: "6%",
                          bottom: "0%",
                          left: "0%",
                          right: "0%",
                          marginTop: "0px",
                          // marginBottom:"10px",
                        }}
                      >
                        <iframe
                          name="ifr"
                          scrolling="yes"
                          fullWidth
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                          }}
                          src={`${doc}`}
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </Grid>
                  ) : null}
                </form>
              </Box>
            </Draggable>
          </Box>
      
      
      </Modal>

): null}
   
    </>
  );
}
